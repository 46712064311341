﻿h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Raleway', sans-serif !important;

    &.poppins {
        font-family: 'Poppins' !important;
    }
}

h1 {
    font-weight: bold;
    line-height: 1;
    color: $primary-blue;
    @include h1size;
}

h2 {
    font-weight: bold;
    line-height: 1.2;
    color: $primary-blue;
    @include h2size;
}

h3 {
    font-weight: bold;
    line-height: 1;
    color: $primary-blue;

    @include h3size;
}

h4 {
    text-transform: uppercase;
    line-height: 30px;
    color: $primary-blue;

    @include h4size;
}
.poppins {
    font-family: 'Poppins' !important;
}
p {
    margin-bottom:25px;
}
div#content {
    ul, ol {
        padding-left: 15px;
        margin-bottom: 30px;

        li {
            @include bodytext;
            color: $black;
            font-family: inherit;

            a {
                color: $light-blue;
            }
        }
    }
}
p, a {
    color: $black;
    font-family: 'Raleway', sans-serif;
    @include bodytext;

    &.white, &.white-text {
        color: $white;
    }

    &.small {
        @include bodytext-sm;
    }
}

p a {
    color: $light-blue;
    text-decoration: underline;

    &:hover {
        text-decoration: none;
    }
}

.btn-wrapper {
    a {
        border-radius: 10px 10px 0px 10px;
        @include bodytext;
        color: $white;
        padding: 0px 20px;
        border: 0;
        font-weight: 600 !important;
        line-height: 2;
        text-decoration: none;
        background: $light-blue;
        border: 2px solid $light-blue;
        color: $white !important;
        position: relative;

        &:hover {
            background: none;
            border: 2px solid $light-blue;
            color: $light-blue !important;
        }
    }
}

.btn {
    border-radius: 10px 10px 0px 10px;
    @include buttonText;
    color: $white;
    padding: 7px 20px;
    border: 0;
    font-weight: 600 !important;
    line-height: 2;
    text-decoration: none;

    @media (max-width: 991px) {
        font-size: 20px;
        padding: 5px 35px;
    }

    &.btn-primary {
        background: $light-blue;
        border: 2px solid $light-blue;
        color: $white !important;
        position: relative;
        display: inline-flex;
        align-items: center;
        padding: 5px 15px;

        img {
            height: 30px;
            width: 30px;
            margin-left: 10px;
        }

        &:before {
            content: '';
            height: 30px;
            width: 30px;
            background-size: cover !important;
            /*background: url(/Assets/images/button-arrow.png) no-repeat center center;*/
            position: absolute;
            top: 10px;
            right: 10px;
        }

        &:hover {
            background: none;
            border: 2px solid $light-blue;
            color: $light-blue !important;
        }

        &.blue {
            background: $primary-blue;
            color: $white !important;
            border: 2px solid $primary-blue;

            &:hover {
                background: none;
                color: $white;
                border: 2px solid $white;
            }
        }

        @media (max-width: 991px) {
            font-size: 18px;
        }
    }

    &.btn-secondary {
        background: $yellow;
        color: $primary-blue;
        border: 2px solid $yellow;

        &:hover {
            background: none;
            color: $primary-blue;
            border: 2px solid $primary-blue;
        }

        &.btn-ghost {
            border-color: $yellow;
            background: none;
            color: $yellow;
        }

        &.white-hover {
            &:hover {
                background: none;
                color: $white;
                border: 2px solid $white;
            }
        }

        &.white {
            background: none;
            color: $white;
            border: 2px solid$white;

            &:hover {
                background: $light-blue;
                color: $white;
                border: 2px solid $light-blue;
            }
        }
    }

    &.btn-md {
        padding: 5px 20px;
        font-size:18px;
        line-height:1.5;

        @media (max-width: 991px) {
            padding: 5px 25px;
        }
    }

    &:focus {
        box-shadow: none;
    }

    &.white {
        background: $white !important;
        color: $light-blue !important;

        &:hover {
            background: $primary-blue !important;
        }
    }

    &.shadow {
        border-bottom: 3px solid $primary-blue;
        border-right: 3px solid $primary-blue;
        box-shadow: none !important;

        &:hover {
            border-bottom: 3px solid $primary-blue !important;
            border-right: 3px solid $primary-blue !important;
        }
    }

    &.btn-switch {padding:5px;
        img {margin-left:7px; width:20px; height:auto;}
        color:$primary-blue; font-size:12px; line-height:13px; font-weight:400 !important;
        @media all and (min-width:$screen_lg_min) {
            background: $light-blue;
            border: 2px solid $light-blue;
            color: $white !important;
            position: relative;
            display: inline-flex;
            align-items: center;
            padding: 5px 15px;
            font-size:20px; line-height:2; font-weight:600 !important;

            img {width: 20px; margin-left: 10px;}

            &:before {
                content: '';
                height: 30px;
                width: 30px;
                background-size: cover !important;
                /*background: url(/Assets/images/button-arrow.png) no-repeat center center;*/
                position: absolute;
                top: 10px;
                right: 10px;
            }

            &:hover {
                background: none;
                border: 2px solid $light-blue;
                color: $light-blue !important;
            }

            &.blue {
                background: $primary-blue;
                color: $white !important;
                border: 2px solid $primary-blue;

                &:hover {
                    background: none;
                    color: $white;
                    border: 2px solid $white;
                }
            }

            @media (max-width: 991px) {
                font-size: 18px;
            }
        }
    }
}

.blue {
    color: $primary-blue !important;
}

.border-blue {
    color: $border-blue !important;
}

.white {
    color: $white !important;

    p {
        color: $white !important;
    }
}

.black {
    color: $black;
}

.yellow {
    color: $yellow !important;
}
.normal {
    font-weight: normal;
}
.light {
    font-weight:300;
}
.transform-none {
    text-transform:none !important;
}
.bold, .font-weight-bold {
    font-weight:600 !important;
}

$em-sizes:(
    0_1:0.1, 0_2:0.2, 0_3:0.3, 0_4:0.4, 0_5:0.5, 0_6:0.6, 0_7:0.7, 0_8:0.8, 0_9:0.9,
    1:1, 1_1:1.1, 1_2:1.2, 1_3:1.3, 1_4:1.4, 1_5:1.5, 1_6:1.6, 1_7:1.7, 1_8:1.8, 1_9:1.9,
    2:2, 2_1:2.1, 2_2:2.2, 2_3:2.3, 2_4:2.4, 2_5:2.5, 2_6:2.6, 2_7:2.7, 2_8:2.8, 2_9:2.9,
    3:3, 3_1:3.1, 3_2:3.2, 3_3:3.3, 3_4:3.4, 3_5:3.5, 3_6:3.6, 3_7:3.7, 3_8:3.8, 3_9:3.9,
    4:4
);
$em-sizes-select:(
    0_1:0.1, 0_2:0.2, 0_3:0.3, 0_4:0.4, 0_5:0.5, 0_6:0.6, 0_7:0.7, 0_8:0.8, 0_9:0.9,
    1:1, 1_1:1.1, 1_2:1.2, 1_3:1.3, 1_4:1.4, 1_5:1.5, 1_6:1.6, 1_7:1.7, 1_8:1.8, 1_9:1.9,
    2:2
);
@each $key, $value in $em-sizes {
    .sz-em-#{$key} {font-size:#{$value}em;}
    .sz-rem-#{$key} {font-size:#{$value}rem;}
    .lh-em-#{$key} {line-height:#{$value};}
    .lh-rem-#{$key} {line-height:#{$value}rem;}
    .ls-#{$key} {letter-spacing:#{$value}px;}
}
$font-weights:100, 200, 300, 400, 500, 600, 700, 800, 900;
@each $fw in $font-weights {
    .fw-#{$fw} {font-weight:#{$fw};}
}

$custom-breakpoints:(
    sm:$screen_sm_min,
    md:$screen_md_min,
    lg:$screen_lg_min,
    xl:$screen_xl_min,
    xxl:$screen_xxl_min
) !default;

@each $key, $value in $custom-breakpoints {
    @media all and (min-width:$value) {
        @each $emkey, $emvalue in $em-sizes-select {
            .sz-#{$key}-em-#{$emkey} {font-size:#{$emvalue}em;}
            .sz-#{$key}-rem-#{$emkey} {font-size:#{$emvalue}rem;}
            .lh-#{$key}-em-#{$emkey} {line-height:#{$emvalue} !important;}
            .lh-#{$key}-rem-#{$emkey} {line-height:#{$emvalue}rem !important;}
        }
    }
}

%lineclamp {display:-webkit-box; -webkit-box-orient:vertical; overflow:hidden; text-overflow:ellipsis;}
@each $lc in (0,1,2,3,4,5,6,7,8,9,10) {
    .line-clamp-#{$lc} {@extend %lineclamp; -webkit-line-clamp:#{$lc};}
}