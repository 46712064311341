﻿.checkout_header {
    border-bottom: 3px solid $lighter-gray;
    padding: 30px 0;

    @media(max-width:767px) {
        padding: 10px 0;
    }

    &.sticky {
        .logo {
            img {
                height: 72px;
            }
        }
    }

    .row {
        align-items: center;
    }

    .plan-detail {
        width: auto;
        display: inline-block;
        padding-right: 30px;

        p {
            @include bodytext;
            font-weight: normal;
            color: $primary-blue;
            text-align: left;
            margin: 0;

            .plan-rate {
                color: $primary-blue;
            }

            .plan-cost {
                color: $primary-blue;

                sup {
                    font-size: 60%;
                }
            }

            a {
                @include bodytext-sm;
                line-height: 35px;
                display: inline-block;
                margin-left: 5px;
                text-decoration: none;
            }
        }
    }

    .checkout-top-links {
        padding-left: 30px;
        border-left: 2px solid $lighter-gray;
        display: inline-block;

        ul {
            margin: 0 !important;
            padding: 0;

            li {
                list-style-type: none;
                width: 100%;
                text-align: left;
                line-height: 28px !important;

                a {
                    color: $primary-blue !important;
                    @include bodytext-sm;
                    font-weight: normal;
                    text-decoration: underline;
                }
            }
        }
    }
}

.checkout-page {
    background-color: $white !important;
    padding: 50px 0;

    .error-message {
        color: $error-red;
    }

    h1 {
        margin: 30px 0;
        color: $primary-blue;
        text-align: center;

        @media (max-width: 991px) {
            margin: 15px 0
        }
    }

    .gray-section {
        background: $lighter-gray-2;
    }

    .dotted-border {
        background-image: linear-gradient(to right, #707070 33%, rgba(255,255,255,0) 0%);
        background-position: bottom;
        background-size: 6px 3px;
        background-repeat: repeat-x;
        height: 2px;
        display: inline-block;
        width: 100%;
    }

    .secured-badge {
        max-width: 151px;
    }

    &.announcement-on {
        .white-box {
            &.order-summary {
                @media (min-width: 992px) {
                    top: 21.5%;
                }
            }
        }
    }

    p {
        @include bodytext;
        color: $black;

        &.gray {
            color: #7D7D7D;
        }

        &.invalid-feedback {
            font-size: 0.875em !important;
            color: #dc3545 !important;
        }

        &.mb-small {
            @media(max-width:767px) {
                font-size: 12px;
                line-height: 1.4;
            }

            a {
                @media(max-width:767px) {
                    font-size: 12px;
                    line-height: 1.4;
                }
            }
        }
    }

    .input-mt {
        margin-top: 5px !important;
    }

    .form-group {
        p {
            @include bodytext;
        }
    }

    .lighter-gray {
        background: $lighter-gray-2;
    }

    .explanation-box {
        background: $lighter-gray-2;
        padding: 20px;
        position: relative;
        margin-left: 20px;
        margin-top: 35px;

        &:before {
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 0 15px 15px;
            border-color: transparent transparent #102247 transparent;
            content: '';
            position: absolute;
            left: -15px;
            top: 0%;
        }
    }

    .disclaimer {
        font-size: 18px;
    }

    .op-tooltip {
        background: #888888;
        font-size: 16px;
        color: $white;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        position: relative;

        &:hover {
            &:before {
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 0 15px 15px;
                border-color: transparent transparent #102247 transparent;
                content: '';
                position: absolute;
                right: 120%;
                right: -70%;
                top: 0%;
            }

            &:after {
                content: attr(data-title);
                padding: 10px;
                background: $lighter-yellow;
                color: $primary-blue;
                @include bodytext;
                width: 370px;
                font-weight: normal;
                position: absolute;
                z-index: 2;
                left: 170%;
                top: 0;
            }
        }
    }

    input {
        border-radius: 0;
        border-color: $border-gray;
        border-width: 1.5px;
        margin: 0 0 15px;
        @include bodytext;
        color: $black !important;

        &.max-w-75 {
            max-width: 75%;
        }

        &[type=number] {
            -webkit-appearance: textarea;
            -moz-appearance: textfield;

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
            }
        }

        &:not([type='checkbox']) {
            &[required] + label:first-letter {
                font-size: 12px;
            }
        }

        &[readonly]:not(.js-datepicker-input),
        &::placeholder {
            color: $dark-gray !important;
        }

        &[disabled],
        &[readonly]:not(.js-datepicker-input) {
            background-color: $readonly-input-bkg !important;
        }
    }

    textarea {
        border-radius: 0;
        border-color: $border-gray;
        border-width: 1.5px;
        width: 100%;
    }

    label {
        color: $black;
        @include bodytext;
        margin-bottom: 2px;
    }

    .error-message {
        color: $error-red;
        @include bodytext-sm;
        line-height: 1.2;
        margin: 5px 0 20px;
        display: none;

        @media (max-width: 991px) {
            font-size: 16px;
        }
    }

    .invalid-summary {
        width: 100%;
        margin: 0.5rem 0;
        font-size: 1em;
        color: $error-red;
    }

    .error {
        input {
            border-color: $error-red;
        }

        .error-message {
            display: block;
        }
    }

    h4 {
        color: $black;
    }

    hr {
        border-color: $black;
        opacity: 1;
    }

    p {
        margin: 0 0 15px;
    }

    p, a {
        @include bodytext;

        &:not(.btn) {
            line-height: 1.5;
        }
    }

    .input-wrapper {
        width: 20px;
        display: block;
        float: left;
        margin-right: 5px !important;

        input {
            margin-top: 5px;
        }
    }

    .reason-button {
        position: absolute;
        margin-left: -1.25em;
    }

    input[type='radio'] {
        height: 17px;
        width: 17px;
        border: 1px solid $light-blue;
        border-radius: 50%;
        color: $light-blue;
        -webkit-appearance: none;
        padding: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $white;

        &:focus {
            box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25)
        }

        &:checked {
            &:after {
                content: '';
                position: relative;
                height: 12px;
                width: 12px;
                background: $light-blue;
                border-radius: 50%;
            }
        }
    }

    input[type='checkbox'] {
        border: 1px solid $light-blue;
        height: 17px;
        width: 17px;
        -webkit-appearance: none;
        padding: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $white;
        margin-right: 10px;

        &:focus {
            box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25)
        }

        &:after {
            content: "\e915";
            font-size: 15px;
            color: $white;
            position: relative;
        }

        &:checked {
            background: $light-blue !important;
            font-family: 'de' !important;

            &:after {
                font-size: 10px;
            }
        }
    }

    .date-picker-wrap {
        position: relative;

        &:after {
            font-family: 'de' !important;
            content: "\e916";
            font-size: 18px;
            color: $primary-blue;
            position: absolute;
            right: 15px;
            top: 43px;
            pointer-events: none;

            @media (max-width: 991px) {
                top: 37px;
            }
        }
    }

    .dropdown-wrapper {
        border-radius: 0;
        position: relative;
        display: inline-block;
        width: 100%;

        &:before {
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 12px 8px 0 8px;
            border-color: #555954 transparent transparent transparent;
            position: absolute;
            right: 12px;
            top: 20px;
            pointer-events: none;
        }

        select {
            -webkit-appearance: none;
            border: 1.5px solid $border-gray;
            border-radius: 0;
            background: $white;
            @include bodytext;
            padding: .375rem .75rem;
            display: inline-block;
            width: 100%;

            &:disabled {
                background: #e9ecef;
            }
        }
    }

    .biling-address, .auto-pay {
        display: none;

        &.open {
            display: block;
        }
    }

    .gray-box {
        background: $light-gray;
        padding: 15px;

        img {
            max-width: 100%;
        }
    }

    .auto-pay-cc, .auto-pay-ach {
        display: none;

        &.selected {
            display: block;
        }
    }

    .credit-cards {
        padding: 10px 0;

        img {
            max-width: 75px;
            max-height: 66px;
            margin-right: 8px;
            transition: all 0.3s ease;

            @media (max-width:767px) {
                max-height: 32px;
            }
        }

        &.card-selected {
            img {
                opacity: 0.3;
                transition: all 0.3s ease;

                &.active {
                    opacity: 1;
                }
            }
        }
    }

    &.order-summary {
        text-align: left;
        color: $primary-blue;
        transition: all 0.3s ease;


        @media (min-width: 992px) {
            position: -webkit-sticky;
            position: sticky;
            top: 14%;
        }

        @media (max-width: 991px) {
            left: 15px;
            right: 15px;
            top: -1500%;
            opacity: 1;
            position: fixed;
        }

        .de-close {
            display: none;

            @media (max-width: 991px) {
                display: block;
                color: $light-blue;
                float: right;
                @include bodytext;
                margin: -30px 0 0;
            }
        }

        .details-title {
            display: block;
            width: 100%;
            text-align: left;
            @include bodytext;
        }

        .price-month {
            padding: 0 0 10px;
            margin: 0 0 15px;
            display: flex;
            width: 100%;
            border-bottom: 2px solid $primary-blue;
            align-items: center;

            .price-per {
                float: left;
                width: 75%;

                .plan-price {
                    font-size: 60px;
                    line-height: 1;

                    sub {
                        top: 0;
                        font-size: 50%;
                        vertical-align: super;
                    }
                }

                .plan-price-per {
                    color: $primary-blue;
                    @include bodytext-sm;
                    display: inline-block;
                    font-weight: 300;
                }
            }

            .plan-duration {
                float: right;
                @include bodytext-sm;
                line-height: 1.2;
            }
        }

        .more-plan-info {
            @media (max-width: 767px) {
                margin: 20px 0 0;
            }
        }

        p {
            color: $primary-blue;
        }

        ul {

            li {

                &:before {
                    color: $primary-blue;
                }
            }
        }
    }

    .terms-links {
        padding: 0 !important;
    }

    ul {
        margin: 0;
        padding: 0;

        li {
            list-style-type: none;
            color: $dark-gray;

            &:before {
                content: "\2022";
                color: $dark-gray;
                display: inline-block;
                width: 20px;
            }
        }
    }

    @media (max-width: 991px) {
        &.open {
            top: 25vh;
            background: $white;
            transition: all 0.3s ease;
            opacity: 1;
            z-index: 20;
        }
    }

    fieldset {
        label {
            @include bodytext;
            margin-left: 5px;
        }

        span, div {
            margin-right: 25px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            margin-left: 0;

            input {
                margin: 0;
            }
        }
    }
}


.backdrop-mobile {
    background: rgba(0,0,0,0.4);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -12;
    opacity: 0;
    transition: all 0.3s ease;

    &.open {
        opacity: 1;
        z-index: 10;
    }

    @media (min-width: 992px) {
        display: none !important;
    }
}

.pink-box {
    background: $light-blue;

    h3 {
        color: $white;
    }
}

.amount-wrapper {
    border-top: 1.5px solid $primary-blue;
    border-bottom: 1.5px solid $primary-blue;
    @include h2size;
    line-height: 1.2;
    font-weight: 200;

    @media (max-width: 991px) {
        font-size: 25px;
    }
}

h4 {
    text-transform: none;
    color: $dark-gray;

    &.border-bottom {
        border-bottom: 1.5px solid $primary-blue !important;
    }
}

ul.terms-links {
    li {
        list-style-type: none;
        display: inline-block;
        width: 100%;

        &:before {
            display: none !important;
        }

        a {
            color: $light-blue;
            text-decoration: underline;
            line-height: 1;
        }
    }
}

ul.social-links {
    margin: 0;
    padding: 0;

    li {
        list-style-type: none;
        margin: 0 5px;
        display: inline-block;

        &:before {
            display: none !important;
        }

        a {
            font-size: 16px;
            line-height: 42px;
            color: $primary-blue;
            text-decoration: none;

            span {
                color: $primary-blue;
            }

            &:hover {
                span {
                    color: $light-blue;
                    text-decoration: none;
                }
            }
        }
    }
}

.de-print {
    color: $light-blue;
    font-size: 35px;
    position: absolute;
    right: 10px;
    top: 30px;
    cursor: pointer;
}

.page {
    display: none !important;

    &.current {
        display: block !important;
    }
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

.plan-summary {
    h3 {
        font-weight: normal;
        line-height: 40px;
    }

    .plan-rate {
        color: $light-blue;
        font-size: 63px;
        float: left;
        line-height: 60px;
    }

    .plan-cost {
        color: $light-blue;
        font-size: 12px;
        display: inline-block;
    }

    .more-info {
        margin-top: 15px;
        padding-top: 15px;
        border-top: 1px solid #f5f5f5;
    }

    .plan-address {
        color: $primary-blue;
        font-size: 30px;
        line-height: 40px;

        div#zipcodeDisplay {
            display: inline;
        }

        a {
            color: $light-blue;
            font-size: 18px;
            text-decoration: none;
            display: inline;
            margin-left: 5px;
        }
    }
}

.confirm-id {
    input {
        float: left;
    }

    label {
        width: 95%;
        display: inline;
        float: left;

        a {
            color: $light-blue;
            text-decoration: underline;
        }
    }

    .invalid-feedback {
        width: 100%;
        margin-top: 15px !important;
        float: left;
    }
}

.password-wrapper {
    position: relative;

    .input-group-text {
        height: calc(100% - 15px);
    }

    .de-eye, .de-eye-off {
        color: #7D7D7D;
        font-size: 20px;
        cursor: pointer;
    }
}

.datepicker-wrapper {
    .input-group-text {
        height: calc(100% - 15px);
    }
}

.deposit-info {
    background: #FDCB2A;
}

.amount-due {
    font-size: 50px;
    line-height: 60px;
    color: $black;
    padding: 10px 0;

    @media (max-width:767px) {
        font-size: 30px;
        line-height: 30px;
    }
}

.pay-deposit {
    max-height: 72px;
}

.ad-example {
    width: 100%;
    height: 275px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #888888;

    h2 {
        color: $white;
    }
}

.was-validated {
    :not(.error-code) {
        input[type="radio"], input[type="checkmark"] {
            background: $white !important;
            border-color: $border-gray;
        }
    }

    .form-select:valid:not([multiple]):not([size]) {
        background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") no-repeat center right;
        border: 1.5px solid #28a745;
        border-radius: 0;
        background-color: #ffffff;
        background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
        background-position: calc(9.75em + 0.75rem) calc(0.25em + 0.375rem);
    }

    input[type='radio'] {
        &:checked {
            &:after {
                background: $light-blue;
            }
        }
    }

    input[type='checkbox'] {
        &:checked {
            background: $light-blue !important;
        }
    }
}

.needs-validation.was-validated {
    input {
        .invalid-feedback {
            display: none !important;
            visibility: hidden !important;
        }

        &:invalid {
            border-color: #dc3545;

            & ~ .invalid-feedback {
                display: block !important;
                visibility: visible !important;
                margin: -5px 0 10px;
            }
        }
    }
}

.pay-by {
    label {
        margin-bottom: 0;
    }
}

.plan-summary-modal {
    .plan-price {
        color: $primary-blue;

        .plan-rate {
            font-size: 48px;
        }

        .plan-cost {
            font-size: 20px;
        }
    }

    .plan-detail {
        a {
            text-decoration: none;
            margin-left: 5px;
        }
    }

    .checkout-links {
        ul {
            margin: 0 !important;
            padding: 0 !important;

            li {
                list-style-type: none;
            }
        }
    }
}

.modal.verify-address-modal {

    .modal-dialog {

        .modal-content {

            .modal-body {

                .address-list {
                    font-family: sans-serif;
                    margin-bottom: 1.5rem;

                    .address-option {
                        display: flex;
                        border: 2px solid;
                        border-color: $medium-gray;
                        margin: 0.5rem 0;
                        padding: 1rem;
                        align-items: center;

                        &.selected {
                            border-color: $lighter-yellow;
                            background-color: $light-gray;
                        }

                        label {
                            color: $black;
                            margin: 0 0 0 0.75rem;
                        }

                        input {
                            margin: 0;
                        }
                    }
                }
            }
        }
    }
}

// My Account
.my-account-checkout-page {
    h2 + p {
        @media (min-width:1024px) {
            font-size: 1.563rem;
        }
    }
}

.my-account-thank-you-page {
    ul li {
        color: $black;

        &:before {
            color: $black;
        }
    }
}
