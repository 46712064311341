﻿.test-wrapper {
    margin: 0 auto;
    padding: 10px;
    width: 950px;
    min-height: 250px;
    /*border: solid 2px gray;*/
    text-align: center;
}

.test-geoip-list {
    width: 500px;
    margin: 0 auto;
    text-align: left;
    font-size: 20px
}

.test-version {
    font-size: 18px;
    margin: 7px;
    font-weight: bold;
}

.test-link {
    a:link {
        text-decoration: none;
    }

    a:visited {
        text-decoration: none;
    }

    a:hover {
        text-decoration: none;
    }

    a:active {
        text-decoration: none;
    }
}

/*** Test Plan Data ***/
#onpoint-test-table {
    table {
        border: solid 1px gray;
        min-width: 800px;
        margin: 0 auto;
        text-align: left;
    }

    td {
        padding: 10px;
        border: solid 1px gray;
        color: #102247;
        font-size: 16px;
    }

    th {
        padding: 5px;
        background-color: #102247ff;
        color: white;
        border: solid 1px black;
        font-size: 14px;
    }

    ul.no-bullets {
        list-style-type: none; /* Remove bullets */
        padding: 0; /* Remove padding */
        margin: 0; /* Remove margins */
    }

    li {
        font-family: Courier New;
        color: #333333;
        margin: 4px;
        font-size: 20px;
    }
}

.plan-loader-wrapper {
    margin: 0 auto;
    padding: 25px;
    max-width: 100% !important;

    div {
        margin: 10px;
    }
}


/*** Health Check ***/
.health-check-wrapper {
    max-width: 1000px;
    margin: 0 auto;

    .pagination {
        display: none;
    }

    .dataTables_length {
        display: none;
    }

    .dataTables_info {
        display: none;
    }

    .results {
        margin-bottom: 75px;
    }
}
