﻿@import "Variables.scss";
/*** Utilities Pages ***/
.utilities-wrapper {
    max-width: 1400px;
    margin: 25px auto !important;
    color: #303030;

    a {
        font-size: 16px !important;
    }

    h1 {
        font-size: 30px;
    }

    h2 {
        font-size: 22px;
    }

    .util-nav a, div {
        margin: 10px 0;
        font-size: 16px;
    }

    a,
    .link-accent-color {
        color: #0015ea;
        text-decoration: none;
        transition: color 0.1s ease-in, background-color 0.1s ease-in;
    }

    a:hover, a:focus,
    .link-accent-color:hover,
    .link-accent-color:focus {
        color: #000e9e;
    }

    .text-link {
        transition: color 0.1s ease-in, background-color 0.1s ease-in;
    }

    .text-link .icon {
        fill: currentColor;
        height: 1em;
        width: 1em;
        margin-left: 3px;
    }

    .link-body-color {
        color: #666666;
        transition: color 0.1s ease-in, background-color 0.1s ease-in;
    }

    .link-body-color:hover, .link-body-color:focus {
        color: #0015ea;
    }

    h1 {
        font-size: 36px;
    }

    h2 {
        font-size: 25px;
    }

    pre {
        font-size: 16px;
        font-weight: bold;
    }

    span {
        margin: 15px 0;
    }
}

.active .page-link {
    color: white !important;
}


