﻿@import "Variables.scss";

body {
    overflow-x:hidden;
}
#backToTop {
    position: fixed;
    bottom: 1.5rem;
    left: 0.75rem;
    cursor: pointer;
    outline: none;
    opacity: 0.5;
    transition: margin-bottom 0.4s ease 0s;
    background: $primary;
    border: 1px solid $white;
    border-radius: 0.5rem;
    box-shadow: 0px 0px 6px rgba($black, 0.4);
    font-size: 1.2rem;
    color: $white;
    z-index:999;
    .icon {
        display: block;
        border: solid black;
        border-width: 0 4px 4px 0;
        display: inline-block;
        padding: 8px;
        transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
        margin: 20px 15px 5px 15px;
    }

    &:focus, &:hover {
        background: $primary-hover;
    }


}