﻿.enrollment-accounts {
    li {
        font-size: 22px;
        border-bottom: solid 1px #dee2e6;
        margin-bottom: 15px;
        padding-bottom: 15px;

        label {
            font-weight: 700;
        }

        &.new-enrollment {
            a {
                position: relative;
                display: block;
                text-decoration: none;
                line-height: 30px;
                text-indent: 40px;
                font-size: 26px;
                font-weight: 500;

                &:before {
                    content: "";
                    display: block;
                    background-image: url('/assets/images/plus-circle.svg');
                    background-size: cover;
                    width: 30px;
                    height: 30px;
                    position: absolute;
                    left: 0;
                }

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}

.onpoint-address-switcher {

    @media (min-width: 992px) {
        button.btn-primary {
            float: right;
        }
    }

    .dropdown-menu {
        border-radius: 0;
        max-width: 450px;
        padding: 0;

        li,
        li a {
            padding: 0;
            font-size: 18px;
            line-height: 25px;
            font-weight: 700;

            + li {
                border-top: solid 1px #dee2e6;
            }

            a {
                padding: 23px 30px;
                display: block;
            }

            span:first-child {
                font-size: 20px;
            }
        }
    }
}
