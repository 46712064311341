﻿/* DEG Vue Component Styles */
.deg-component {
    border: solid 2px white;
    padding: 10px
}

.sign-up-wrapper {
    border: none;
    display: flex;
    max-width: 300px;

    &.light {
        input {
            border-bottom-color: $white;
            color: $white;

            &::placeholder {
                color: $white;
            }
        }
    }

    input {
        -webkit-appearance: none;
        outline: none;
        background: none;
        border: none;
        border-bottom: 2px solid $primary-blue;
        padding: 5px 10px;
        float: left;
        width: 95%;
        color: $primary-blue;
        @include bodytext;

        &::placeholder {
            color: $primary-blue;
            @include bodytext;
            opacity: 1;
            color: $primary-blue;
        }

        &.is-invalid {
            border-color: #dc3545;
        }
    }

    button {
        -webkit-appearance: none;
        outline: none;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $light-blue;
        width: 50px;
        float: right;
        border-radius: 10px 10px 0px 10px;
        img {
            display:none;
        }
        span {
            color: $white;
            font-size: 20px;
        }
    }
}

.icheck-iframe {
    max-width: 100%;
}

.energy-bot-wrapper {
    min-height: 400px;

    #address-search {
        height: 100%;
    }
}