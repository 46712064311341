﻿.login-form {
    input {
        border-radius: 0;
        border-color: $border-gray;
        border-width: 1.5px;
        border-style: solid;
        margin: 0 0 15px;
        line-height: 1.5;
        @include bodytext;
        padding: 3px 15px;
        -webkit-appearance: textarea;
        -moz-appearance: textfield;
        width: 100%;
    }

    label {
        color: $black;
        line-height: 1.3;
        @include bodytext;
        margin-bottom: 2px;
    }
}
