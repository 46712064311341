﻿@import "Variables.scss";

img {
    max-width: 100%;
}

.hero-block {
    background-size: cover;
    min-height: 760px;
}

.heroblock {
    padding: 0px;
}

.card-block {
    color: $primary-blue;
    background-size: cover !important;
    height: 100%;

    h1 {
        font-weight: bold;
        text-align: center;
    }

    @media all and (min-width: 992px) {
        min-height: 738px;
        padding: 89.7px 177px 72px 197px;

        .inner-div {
            padding: 40px 41.8px 31.1px 41.8px;
        }
    }
}

.list-block {
    @media all and (min-width: 992px) {
        .card-block {
            min-height: 125px !important;
        }
    }
}

.containerblock {
    padding: 50px 0;
}

.cardblock {
    padding: 0px;
}

.zip-code-block {
    background: $primary-blue;
    padding: 30px;

    @media (max-width: 991px) {
        padding: 30px 15px;
    }

    h1, p {
        color: $white;
    }

    p {
        @include bodytext;
        margin: 0;
    }

    .zip-summary {
        display: flex;
        align-content: center;
        justify-content: center;

        @media (max-width: 991px) {
            flex-wrap: wrap;
        }

        input.zip-in {
            border-bottom: 2px solid $white;
            background: none;
            border-radius: 0;
            border-left: 0;
            border-right: 0;
            border-top: 0;
            padding: 2px 15px;
            @include bodytext;
            color: $white;
            margin: 0 15px;
            width: 120px;
            text-align: center;
        }

        p {
            @media (max-width: 991px) {
                width: 100%;
                margin: 0 0 20px;
            }
        }
    }

    .btn {
        &:hover {
            background: $yellow;
            color: $primary-blue;
            border-color: $yellow;
        }
    }
}

.top-section {
    @media (max-width: 767px) {
        background-size: 250% !important;
        background-position: top right !important;
        background-color: #7f3b72 !important;
    }

    .content-block-text {
        h4 {
            text-transform: none;
            color: $white;
        }

        p {
            color: $white;
        }
    }
}

.hero-banner-block {
    padding: 80px 0;
    background: $primary-blue;

    &.margin-bottom {
        margin: 0 0 100px;
    }

    h1, h2, h3, h4 {
        color: $primary-blue;
    }

    h3 {
        text-transform: uppercase;
    }

    @media (max-width:992px) {
        padding: 50px 0;
    }

    .hero-content {
        p {
            color: $white;
        }
    }

    .hero-content-row {
        & > div:not([class]) {
            width: 100%;

            & > .row {
                align-items: stretch !important;
                justify-content: center !important;

                .zipcodectablock {
                    padding: 0;
                }
            }
        }
    }

    .links-block {
        padding: 25px;
        background: $white;
        border-radius: 10px 10px 0px 10px;
    }
}

.hero-banner {
    padding: 80px 0;
    background: $primary-blue;

    h1 {
        color: $yellow;
    }

    p {
        color: $white;
    }

    &.w-image {
        position: relative;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: transparent linear-gradient(175deg, #102247F7 0%, #0000003C 100%) 0% 0% no-repeat padding-box;
            opacity: 0.83;
            z-index: 1;
        }

        .container {
            position: relative;
            z-index: 2;
        }
    }
}

.links-block {
    h3 {
        color: $black;
    }

    a {
        color: $light-blue;
        text-decoration: none;
    }

    .list-of-links {
        .block.link {
            border-bottom: 1px solid #BABABA;
            padding: 5px 0;

            &:last-child {
                border: none;
            }
        }
    }
}

.home-content {
    .pink-boxes-wrapper {
        margin-top: -120px;
        position: absolute;
        z-index: 10;
        padding-bottom: 0;

        @media (max-width:992px) {
            position: relative;
            margin-top: 15px;
            margin-bottom: 15px;
        }
    }
}

.block.purpleboxcard {
    padding: 0;
}

.pink-boxes-wrapper {
    padding-bottom: 50px;

    @media (max-width:992px) {
        padding-bottom: 0;
    }

    &.pull-up {
        margin-top: -120px;

        @media (max-width:992px) {
            margin-top: 15px;
        }
    }

    .purple-boxes-row {
        & > div:not([class]) {
            width: 100%;

            & > .row {
                align-items: stretch !important;
                justify-content: center !important;

                .block.purpleboxcard {
                    background: none;
                    border-radius: 0;
                    border: none;
                    border-left: 1.5px solid $white;

                    &:first-child {
                        border: none;
                    }

                    @media (max-width:767px) {
                        border-left: 0;
                        border-top: 1.5px solid $white;
                    }
                }
            }
        }
    }

    .row {
        .pink-box-block {
            background: $light-blue;
            flex-grow: 1;
            height: 100%;

            a {
                display: block;
                height: 100%;
                transition: all 0.3s ease;

                &:hover {
                    text-decoration: none;
                    background: $primary-blue;
                }
            }

            &:first-child {
                .card {
                    border: none;
                }
            }

            .card {
                background: none;
                border-radius: 0;
                border: none;
                border-left: 1.5px solid $white;

                @media (max-width:767px) {
                    border-left: 0;
                    border-top: 1.5px solid $white;
                }

                h1, h2, h3, h4, p {
                    color: $white;
                }

                a {
                    color: $white;
                    text-decoration: none;

                    &:hover {
                        background: none;
                        color: $primary-blue;
                    }
                }
            }
        }
    }

    .icon-text {
        display: flex;
        margin: 0 0 20px;

        .icon {
            width: 50px;
            margin-right: 15px;
        }
    }
}

.subtitle {
    text-transform: uppercase;
}

.imagecontentblock {
    .content-block-wrap {
        &.white {
            .btn.white {
                background: none !important;
                border-color: $white !important;
                color: $white !important;

                &:hover {
                    background: $white !important;
                    color: $light-blue !important;
                }
            }
        }
    }

    ul {
        columns: 3;
        padding: 0;
        margin: 15px 0;
        padding-left: 0 !important;

        @media(max-width:767px) {
            columns: 2;
        }

        li {
            list-style-type: none !important;
            color: $black;
            font-family: 'Raleway', sans-serif;
        }
    }
}

ul.no-columns {
    columns: 1 !important;
    padding: 0;
    margin: 15px 0;
    padding-left: 0 !important;

    li {
        list-style-type: none !important;
        position: relative;
        padding-left: 10px;

        &:before {
            content: '';
            background: $black;
            height: 5px;
            width: 5px;
            position: absolute;
            left: 0;
            top: 13px;
            border-radius: 50%;
        }
    }
}

.horizontalcontentlistblock {
    .content-block-text {
        height: 100%;

        .content-left-block {
            @media(min-width:1199px) {
                padding-right: 7%;
            }
        }

        .content-block-wrap {
            padding: 70px 0;
            height: 100%;
            display: flex;
            align-items: center;
            position: relative;

            .background-box {
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                z-index: 1;
            }

            .content {
                position: relative;
                z-index: 2;
            }

            h1, h2, h3, h4, p {
                color: $black;
            }

            .icon-text-block h4 {
                color: $primary-blue;
            }

            &.custom-text-color {
                h1, h2, h3, h4, p {
                    color: inherit;
                }
            }

            &.extra-pt {
                padding-top: 200px;
            }

            .btn {
                margin-top: 20px;
            }

            .white {
                color: $white;
            }

            .icon-wrapper {
                width: 65px;
                float: left;
                padding-right: 15px;
                padding-left: 0;

                & ~ div {
                    flex-shrink: 2;
                }
            }

            &.icons-top {
                @media(min-width:768px) {
                    .iconcardblock {
                        padding-right: 50px;
                    }
                }

                .icon-wrapper {
                    width: 92px;

                    @media(max-width:767px) {
                        width: 98px;

                        img {
                            height: 100%;
                            width: auto;
                        }
                    }
                }
            }

            &.transparent-box {
                @media (max-width:767px) {
                    padding: 0;
                    margin-left: -15px;
                    margin-right: -15px;
                }
            }

            &.image-right {
                .row {
                    @media (max-width:767px) {
                        flex-direction: column-reverse;
                    }
                }
            }
            /*&.no-image-mobile {
                @media (max-width:992px) {
                    background: none !important;
                }

                @media (max-width:992px) {
                    &.white-text {
                        h1, h2, h3, h4, p {
                            color: $dark-gray;
                        }
                    }
                }

                @media (max-width:992px) {
                    .btn {
                        color: $dark-gray;
                        border-color: $dark-gray;
                        background: none;
                    }
                }
            }*/
            .cards-borders {
                padding: 0 15px;

                @media(min-width:992px) {
                    .block.iconcardblock:nth-child(n+3) {
                        padding-top: 25px;
                        margin-top: 15px;
                        border-top: 2px solid #F5F5F5;
                        position: relative;

                        &:before {
                            top: -2px;
                            left: 0;
                            width: 70px;
                            content: '';
                            height: 2px;
                            background: $white;
                            position: absolute;
                        }

                        &:after {
                            top: -2px;
                            right: 0;
                            width: 40px;
                            content: '';
                            height: 2px;
                            background: $white;
                            position: absolute;
                        }
                    }
                }

                @media(max-width:991px) {
                    .block.iconcardblock:nth-child(n+2) {
                        padding-top: 25px;
                        margin-top: 25px;
                        border-top: 2px solid #F5F5F5;
                    }
                }
            }

            &.extra-pt {
                @media (max-width:992px) {
                    padding-top: 25px;
                }
            }

            .cards-row-wrapper {
                & > div:not([class]) {
                    width: 100%;

                    & > .row {
                        align-items: stretch !important;
                        justify-content: center !important;
                    }
                }
            }
        }

        ul {
            margin: 10px 0;
            padding: 0;

            li {
                list-style-position: inside;
            }

            &.three-col {
                color: $white;
                column-count: 3;

                @media (max-width:767px) {
                    column-count: 2;
                }

                li {
                    list-style-type: none;
                }
            }
        }
    }
}

.icon-text-block {
    .icon-wrapper {
        width: auto;
        padding-right: 20px;
        color: $primary-blue;
    }

    h4 {
        text-transform: none;
        font-weight: bold;
    }

    img {
        height: 62px;
    }

    p {
        color: $dark-gray;
    }

    .phone-number-link {
        text-decoration: none;
    }
}

.horizontalcontentlistblock {
    h4 {
        @media (max-width:767px) {
            font-size: 30px;
        }
    }
}

.blog-main-block {
    padding: 56px 0;

    @media (max-width:992px) {
        padding: 25px;
    }

    .blog-carousel {
        .owl-stage {
            display: flex;

            .owl-item {
                display: flex;
                flex: 1 0 auto;
            }
        }

        .blog-individual {
            padding: 0 15px;
            align-items: stretch;

            .blog-thumb {
                height: 400px;

                @media (max-width:767px) {
                    height: 280px;
                }
            }

            .blog-title {
                @include bodytext;
                color: $primary-blue;
                margin: 15px 0;
            }

            .blog-summary {
                @include bodytext-sm;
                color: $dark-gray;
                margin-bottom: 15px;
            }

            .read-more {
                @include bodytext-sm;
                color: $primary-blue;
                text-decoration: none;
            }
        }


        &.owl-carousel {
            @media (max-width:992px) {
                padding: 0 15px;
            }

            .owl-nav {
                .owl-prev, .owl-next {
                    height: 30px;
                    width: 30px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: $light-blue;
                    position: absolute;

                    span {
                        color: $white;
                        font-size: 14px;
                    }
                }

                .owl-prev {
                    top: 42%;
                    left: -50px;

                    @media (max-width:992px) {
                        left: 15px;
                    }
                }

                .owl-next {
                    top: 42%;
                    right: -50px;

                    @media (max-width:992px) {
                        right: 15px;
                    }
                }
            }
        }
    }
}

.blog-list-block {
    padding: 98px 0 56px 0;
    background: #f2f2f2;

    .blog-list-block-row {
        & > .col-md-6 {
            @media (min-width: 768px) {
                & {
                    width: 33.33333333%;
                }
            }
        }
    }

    @media (max-width:992px) {
        padding: 48px 0 8px 0;
    }
}

.testimonials {
    margin: 50px 0;

    @media (max-width:767px) {
        margin: 20px 0;
        width: 100%;
        max-width: none;
        flex: 100%;
    }

    & ~ .htmlblock {
        margin: 50px 0;

        @media (max-width:767px) {
            margin: 10px 0 20px;
            width: 100%;
            max-width: none;
            flex: 100%;
        }
    }
}

.testimonial-main-block {

    .testimonial-carousel {
        margin: 20px 0 0;

        .testimonial-individual {
            padding: 0 0 30px;

            .testimonial-summary {
                @include bodytext-sm;
                line-height: 1.4;
                margin-bottom: 15px;

                p {
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                }
            }

            .person-name {
                @include bodytext;
                font-weight: bold;
                color: $primary-blue;
                margin: 25px 0 0;
                text-decoration: none;
            }

            .person-location {
                font-size: 20px;
                color: $primary-blue;
                line-height: 1.4;
                margin-bottom: 15px;
            }
        }
    }

    .tns-controls {
        position: absolute;
        bottom: 0;
        right: 0;
        display: flex;
        top: auto;
        z-index: 10;

        button {
            -webkit-appearance: none;
            background: none;
            border: none;
            padding: 0;
        }

        .cv-prev, .cv-next {
            height: 30px;
            width: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: $primary-blue;
            border-radius: 10px;

            @media (max-width:991px) {
                height: 40px;
                width: 40px;
                position: relative !important;
                margin: 0;

                &:first-child {
                    margin-right: 2px;
                }
            }

            span {
                color: $white;
                font-size: 14px;
                transform: rotate(90deg);
            }
        }

        .cv-prev {
            bottom: 0;
            right: 32px;
            position: absolute;
            border-top-left-radius: 0;
            margin-right: 3px;

            span {
                margin: -2px 0 0;
            }

            @media (max-width:992px) {
                left: 0px;
            }
        }

        .cv-next {
            border-bottom-right-radius: 0;

            span {
                margin: 2px 0 0;
            }

            @media (max-width:992px) {
                right: 0px;
            }
        }

        .cv-item {
            margin: 0 0 -40px !important;
        }
    }
}

.bottom-cta-block {
    background: $light-blue;
    padding: 25px 0;

    @media (max-width:767px) {
        padding: 30px 0;
    }

    h3 {
        @include h3size;
        color: $white;

        @media (max-width:767px) {
            margin-bottom: 15px;
        }
    }
}

.small-cta-block {
    background: $primary-blue;
    padding: 25px 35px;
    border-radius: 10px 10px 0px 10px;

    h1, h2, h3, h4, h5, h6, p {
        color: $white !important;
    }
}

.plan-grid {
    justify-content: center;
    padding-bottom: 80px;
    margin: 0 15px;

    @media (max-width:767px) {
        margin: 0;
    }

    * {
        transition: all 0.5s ease;
    }

    .plan-grid-item {
        width: 25%;
        box-sizing: border-box;
        padding: 80px 10px 0;
        margin: 20px 0;
        position: relative;
        overflow: hidden;

        &:first-child {
            z-index: 90;
        }

        &:nth-child(2) {
            z-index: 89;
        }

        &:nth-child(3) {
            z-index: 88;
        }

        &:nth-child(4) {
            z-index: 87;
        }

        &:nth-child(5) {
            z-index: 86;
        }

        &:nth-child(6) {
            z-index: 85;
        }

        &:nth-child(7) {
            z-index: 84;
        }

        &:nth-child(8) {
            z-index: 83;
        }

        &:nth-child(9) {
            z-index: 82;
        }

        &:nth-child(10) {
            z-index: 81;
        }

        &:nth-child(11) {
            z-index: 80;
        }

        &:nth-child(12) {
            z-index: 79;
        }

        @media (max-width: 767px) {
            padding: 0;
            margin-bottom: 0 !important;
        }

        @media (min-width: 768px) {
            margin: 20px 0 -80px;
        }

        &:hover {
            @media (max-width: 767px) {
                transform: none;
            }
        }

        .preferred-plan {
            padding: 20px 0;
            text-align: center;
            @include h3size;
            font-weight: 700;
            color: $white;
            text-transform: uppercase;

            @media (min-width: 768px) {
                position: absolute;
                top: 0;
                left: 10px;
                right: 10px;
            }
        }

        &.featured-variant {
            overflow: hidden;

            .plan-header {
                position: absolute;
                top: 32px;
                left: 10px;
                right: 10px;
                border-radius: 10px 10px 0px 0px;
                padding: 15px 40px;

                @media (max-width:767px) {
                    position: absolute;
                    top: 0px;
                    left: 0;
                    right: 0;
                }

                h4 {
                    margin: 0;
                }

                .icon-wrapper {
                    position: relative;

                    img {
                        position: absolute;
                        top: -10px;
                        right: -25px;
                        max-height: 70px;
                    }
                }
            }

            .main-view {
                border-top-left-radius: 0;
                border-top-right-radius: 0;

                .plan-more-info {
                    left: 15px;
                    right: 15px;
                    top: 30px;
                    bottom: 5px;
                    padding: 15px 20px 0;
                    visibility: hidden;

                    @media (max-width: 767px) {
                        left: 0;
                        right: 0;
                        top: 0;
                    }
                }

                .plan-price-wrap {
                    display: flex;
                    align-items: flex-end;
                    justify-content: center;
                }

                .plan-detail-img {
                    position: absolute;
                    top: 10px;
                    right: 10px;
                    max-height: 38px;
                }

                &.open {
                    @media (max-width: 767px) {
                        padding-bottom: 80px;
                    }

                    .plan-more-info {
                        @media(min-width:768px) {
                            height: calc(100% - 30px);
                            top: 32px;
                        }
                    }
                }
            }

            .preferred-plan {
                background: $light-blue;
            }

            .plan-more-info {
                background: $primary-blue;
                padding: 20px 10px;
                color: $white;
                visibility: hidden;

                .details-title {
                    display: block;
                    width: 100%;
                    text-align: left;
                    @include bodytext;
                    font-weight: bold;
                }

                p {
                    color: inherit !important;
                }
            }

            @media (max-width:767px) {
                padding: 45px 0px 0;
            }
        }

        .main-view {
            text-align: center;
            border-radius: 10px 10px 0px 10px;
            border: 3px solid $primary-blue;

            @media (min-width: 768px) {
                height: 100%;
                min-height: 430px;
            }

            ul {
                padding-left: 15px;
            }

            .plan-info {
                padding: 25px 25px 150px;
                visibility: visible;
                opacity: 1;
                display: flex;
                flex-direction: column;
                height: 100%;

                &.non-featured {
                    position: relative;
                    top: -1px;

                    &:before {
                        content: "";
                        height: 60px;
                        background-size: contain;
                        width: 46px;
                        position: absolute;
                        top: -2px;
                        right: 15px;
                    }
                }

                @media (max-width: 767px) {
                    padding: 25px 30px 25px;
                }

                @media (min-width: 768px) {
                    .plan-buttons-wrap {
                        position: absolute;
                        left: 0;
                        right: 0;
                        bottom: 0;
                    }
                }
            }

            .plan-name-wrap {
                position: relative;
                padding-bottom: 20px;
            }

            .plan-name {
                color: $primary-blue;
                font-weight: bold;
                @include bodytext;
                display: inline-block;
                width: 100%;
                padding: 0 30px;
            }

            .plan-ala {
                font-size: 15px;
                font-weight: bold;
                color: $dark-gray;
                position: absolute;
                left: 0;
                z-index: 10;
                right: 0;
                bottom: 0px;
            }

            .plan-price-wrap {
                .plan-price {
                    color: $primary-blue;
                    font-size: 56px;
                    line-height: 1;
                    font-family: 'Raleway', sans-serif;

                    sub {
                        top: 0;
                        font-size: 50%;
                        vertical-align: super;
                    }
                }

                .plan-price-per {
                    color: $primary-blue;
                    @include bodytext;
                    display: inline-block;
                    margin-left: 0;
                    font-family: 'Raleway', sans-serif;
                    margin-bottom: 5px;
                }
            }

            .plan-length {
                display: block;
                width: 100%;
                @include h3size;
                color: $primary-blue;
                font-weight: 400;
                font-family: 'Raleway', sans-serif;
            }

            ul {
                margin: 20px 0;
                padding: 0;

                @media (max-width: 767px) {
                    display: inline-block;
                }

                li {
                    text-align: left;
                    @include bodytext;
                    color: $dark-gray;
                    font-weight: 300;
                    font-family: 'Raleway', sans-serif;
                    list-style-type: none;
                    position: relative;
                    padding-left: 15px;

                    &:before {
                        content: '';
                        background: $dark-gray;
                        height: 6px;
                        width: 6px;
                        border-radius: 50%;
                        position: absolute;
                        left: 0;
                        top: 17px;
                    }
                }
            }

            .plan-buttons-wrap {
                padding: 20px 35px 30px !important;
                align-self: flex-end;

                button {
                    width: 100%;
                    padding: 0 15px;
                }

                @media (max-width:767px) {
                    padding: 20px 25px 30px !important;
                    align-self: center;
                }
            }

            .more-info {
                color: $primary-blue;
                @include bodytext-sm;
                cursor: pointer;

                &:hover {
                    color: $light-blue;
                }
            }

            &.open {
                @media (max-width: 767px) {
                    padding-bottom: 40px;
                }

                .plan-info {
                    opacity: 0;
                    visibility: hidden;
                }

                .plan-more-info {
                    opacity: 1;
                    visibility: visible;
                    margin: 0;

                    @media(min-width:768px) {
                        height: calc(100% - 80px);
                    }

                    @media (max-width: 767px) {
                        top: 0;
                    }

                    hr {
                        margin: 0 !important;
                        background: $white;
                    }
                }
            }

            .plan-more-info {
                background: $primary-blue;
                padding: 15px 20px 10px;
                color: $white;
                text-align: left;
                position: absolute;
                left: 10px;
                right: 10px;
                bottom: 0;
                top: 80px;
                margin: 300% 0 0;
                border-radius: 10px 10px 0px 10px;
                visibility: hidden;
                flex-direction: column;

                @media (max-width: 767px) {
                    left: 0px;
                    right: 0px;
                }

                .details-title {
                    display: block;
                    width: 100%;
                    text-align: left;
                    @include bodytext;
                    font-weight: bold;
                }

                .price-month {
                    padding: 10px 0;
                    display: flex;
                    width: 100%;
                    align-items: center;

                    .price-per {
                        float: left;
                        width: 75%;

                        .plan-price {
                            color: $white;
                            font-size: 48px;
                            line-height: 1;

                            sub {
                                top: 0;
                                font-size: 50%;
                                vertical-align: super;
                            }
                        }

                        .plan-price-per {
                            color: $white;
                            font-size: 12px;
                            display: inline-block;
                            margin-left: 0;
                            font-weight: 300;
                        }
                    }

                    .plan-duration {
                        float: right;
                        font-size: 16px;
                        line-height: 1.2;
                    }
                }

                .more-plan-info {
                    @media (max-width: 767px) {
                        margin: 20px 0 0;
                    }

                    .plan-buttons-wrap {
                        padding: 20px 0 0 !important;
                        position: relative;
                        left: 0;
                        right: 0;
                        bottom: 0;

                        .btn-primary.white {
                            &:hover {
                                color: $white !important;
                                background: $light-blue !important;
                                border-color: $white !important;
                            }
                        }
                    }
                }

                .more-plan-info li {
                    color: inherit;
                    list-style-type: none;
                }

                p {
                    color: $white;
                    font-size: 20px;
                    line-height: 25px;
                }

                ul {
                    margin: 0;
                    padding: 0;

                    li {
                        list-style-type: none;
                        font-size: 20px;
                        line-height: 25px;

                        &:before {
                            background: $white;
                            color: $white;
                            top: 10px;
                        }

                        a {
                            color: $white !important;
                            text-decoration: underline;
                            font-size: 16px;
                            line-height: 24px;

                            &:hover {
                                color: $white;
                            }
                        }
                    }
                }

                .more-info {
                    color: $white;
                    font-size: 18px;
                }
            }
        }

        button.btn-primary {
            text-transform: uppercase !important;
            @include bodytext;
            padding-top: 5px !important;
            padding-bottom: 5px !important;
            display: inline-flex;
            align-items: center;
            justify-content: center;
        }
    }
}

.footer-copy {
    p {
        @include bodytext;
    }
}

.contact-wrapper {
    padding: 0 15px;
}

.containerblock {
    padding: 0;

    @media(max-width:767px) {
        &.col-6, &.col-8 {
            width: 100% !important;
        }

        .container {
            .block {
                & > .container {
                    padding: 0;
                }
            }
        }
    }
}

.contact-container-block {
    position: relative;
    padding: 0 15px;
    margin: 0 15px;

    @media(max-width:767px) {
        &:before {
            position: absolute;
            content: '';
            top: 0;
            left: -30px;
            bottom: 0;
            width: 30px;
        }

        &:after {
            position: absolute;
            content: '';
            top: 0;
            right: -30px;
            bottom: 0;
            width: 30px;
        }

        .container-row {
            padding: 0;
        }

        & > .container {
            & > .row {
                margin: 0 !important;

                & > .block {
                    padding: 25px 0 !important;

                    &:first-child {
                        &:last-child {
                            border: none;
                            padding-left: 0;
                            padding-right: 0;
                        }
                    }

                    .block {
                        padding: 0 !important;
                    }
                }
            }
        }

        .icon-text-block {
            .icon-wrapper {
                padding-left: 0;
                padding-right: 10px;

                img {
                    max-width: 28px;
                    height: auto;
                }
            }

            .icon-text-content {
                padding-right: 30px;
            }
        }
    }

    & > .container {
        & > .row {
            .containerblock {

                .container-row {
                    & > .container {
                        & > .row {
                            margin: 0 !important;

                            .iconcardblock {
                                width: 100% !important;
                                max-width: 100% !important;
                                flex: 0 0 100% !important;
                            }
                        }
                    }
                }
            }
        }
    }
}

.container-row {
    width: 100%;
    padding: 50px 0;

    @media(max-width:767px) {
        padding: 20px 0;
    }

    &.margin-bottom {
        margin: 0 0 50px;
    }

    &.border-blocks {
        @media (min-width:768px) {
            & > .container {
                & > .row {
                    & > .block {
                        &:not(.iconcardblock):first-child {
                            padding-right: 80px;
                            border-right: 1px solid #7D7D7D;

                            .block {
                                border: none;
                            }
                        }

                        &:not(.iconcardblock):nth-child(2) {
                            padding-left: 80px;

                            .block {
                                border: none;
                            }
                        }
                    }
                }
            }
        }

        @media (max-width:767px) {
            .container {
                & > .row {
                    & > .block {
                        &:first-child {
                            border-bottom: 1px solid #7D7D7D;

                            &:last-child {
                                border: none;
                            }
                        }
                    }
                }
            }
        }

        &.white-border {
            @media (min-width:768px) {
                .container {
                    & > .row {
                        & > .block {
                            &:not(.iconcardblock):first-child {
                                border-right: 1px solid $white;
                                padding-left: 80px;

                                .block {
                                    border: none;
                                }
                            }

                            &:not(.iconcardblock):nth-child(2) {
                                padding-left: 80px;

                                .block {
                                    border: none;
                                }
                            }
                        }
                    }
                }
            }

            @media (max-width:767px) {
                .container {
                    & > .row {
                        & > .block {
                            &:not(.iconcardblock):first-child {
                                border-bottom: 1px solid #7D7D7D;

                                &:last-child {
                                    border: none;
                                }

                                .block {
                                    border: none;
                                }
                            }
                        }
                    }
                }
            }
        }

        &.py-0 {
            @media (min-width:768px) {
                .container {
                    & > .row {
                        & > .block {
                            &:not(.iconcardblock):first-child {
                                padding: 50px;

                                .block {
                                    padding: 0;
                                }
                            }

                            &:not(.iconcardblock):nth-child(2) {
                                padding: 0;
                            }
                        }
                    }
                }

                .container-row {
                    padding: 0;
                }
            }

            @media (max-width:767px) {
                .container {
                    & > .row {
                        & > .block {
                            &:not(.iconcardblock):first-child {
                                padding: 15px;

                                .block {
                                    padding: 0;
                                }
                            }
                        }
                    }
                }
            }
        }

        &.white-text {
            color: $white;

            p, a, h1, h2, h3, h4, h5, h6 {
                color: $white !important;

                &.phone-number-link:hover {
                    color: $light-blue !important;
                }
            }
        }
    }

    .container-row {
        .block {
            margin: 0 0 15px;

            &:last-child {
                margin: 0;
            }
        }

        .iconcardblock:not([class^="col-"]) {
            .row {
                margin-left: 0;
                margin-right: 0;
            }
        }
    }
}

.zipcodectablock {
    display: inline-flex;
}

.zip-code-block-main {
    background-color: #002365;
    color: #ffffff;
    text-decoration: none;
    padding: 160px 0;

    @media (max-width: 991px) {
        padding: 70px 0;
    }

    h1 {
        font-weight: bold;
        color: $yellow;
        margin-bottom: 25px;
    }

    .yellow {
        color: $yellow;
    }

    .white-text {
        max-width: 650px;
        display: inline-block;
    }

    .zip-input {
        height: 50px;
        border-radius: 0px;

        @media (max-width:991px) {
            max-width: none;
            width: 100%;
            margin: 0 0 25px;
        }
    }

    .btn-zip-submit {
        background-color: #f7dc6c;
        color: #002365;
        height: 60px;
        border-radius: 0px;
    }

    .form-check {
        display: inline-flex;
        align-items: center;

        &:first-child {
            margin-right: 15px;
        }

        input[type='radio'] {
            height: 21px;
            width: 21px;
            border: 1px solid $light-blue;
            border-radius: 50%;
            color: $light-blue;
            -webkit-appearance: none;
            padding: 2px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: $white;

            &:checked {
                background: $light-blue;
            }
        }

        label {
            color: $white;
            margin-left: 10px;
            line-height: 2;
        }
    }

    .radio-wrappers {
        display: inline-flex;
        outline: none;
        max-width: 680px;

        @media(max-width:767px) {
            max-width: none;
            width: 100%;
            display: inline-block;
        }

        .form-check {
            @media(max-width:767px) {
                margin-left: 0;
                padding-left: 0;
                float: left;
                display: block;

                & ~ .form-check {
                    float: right;
                }
            }
        }
    }

    .input-wrapper {
        display: inline-flex;
        flex-wrap: wrap;
        outline: none;
        max-width: 680px;
        background: none;
        margin: 15px 0 0;
        position: relative;

        @media (max-width: 991px) {
            max-width: none;
            width: 100%;
        }

        input {
            padding: 8px 25px;
            -webkit-appeaerance: none;
            width: auto;
            color: $white;
            border: none;
            outline: none;
            text-align: center;
            background: none;
            border-bottom: 1px solid $white;
            margin-right: 20px;

            @media (max-width: 991px) {
                color: $primary-blue;
                background: $white;
                margin-bottom: 15px;
                margin-right: 0;
                width: 100%;
            }

            @media(min-width:991px) {
                max-width: 300px;
            }

            &::placeholder {
                opacity: 1;
                color: $white;
                @include bodytext;

                @media (max-width: 991px) {
                    color: $primary-blue;
                }
            }

            &:focus::placeholder {
                color: transparent;
            }
        }

        .btn.btn-primary {
            padding: 5px 15px;
            flex-grow: 1;
            width: auto;
            min-width: 255px;

            &:hover {
                color: $light-blue !important;
                border-color: $white !important;
                background: $white !important;
            }

            @media(max-width:991px) {
                display: flex;
                justify-content: center;
            }
        }
    }

    .error-message {
        color: $error-red;
        @include bodytext-sm;
        line-height: 2;
        margin: 5px 0 20px;
        width: auto;
        position: absolute;
        z-index: 10;
        justify-content: center;
        background: $white;
        padding: 4px 8px;
        /*display: none;*/
        float: left;
        top: 65px;

        &:before {
            content: '';
            width: 0;
            height: 0;
            border-width: 0 15px 25px 15px;
            border-color: transparent transparent #FFFFFF transparent;
            border-style: solid;
            position: absolute;
            top: -10px;
            z-index: 0;
        }
    }

    .error {
        input {
            border-color: $error-red;
        }

        .error-message {
            display: inline-flex;
        }
    }
}

.logos-row {
    width: 100%;
    background: $lighter-yellow;
    padding: 20px 0;

    .logoblock {
        width: auto;

        img {
            max-height: 80px;
            margin: 0 25px;

            @media(max-width:767px) {
                margin: 0;
                max-width: 100%;
                max-height: 65px;
                display: inline;
            }
        }

        @media(max-width:767px) {
            width: 33.33%;
            text-align: center;
        }
    }
}

.block-purple {
    background: $primary-blue;

    h1, h2, h3, h4, h5, p {
        color: $white;
    }

    .btn {
        margin-top: 25px;
    }
}

.tabs-container {
    ul.tabs-list {
        margin: 0 !important;
        padding: 0 !important;

        @media(max-width:767px) {
            display: inline-flex;
            width: 100%;
        }

        li {
            display: inline-block;
            list-style-type: none;

            @media(max-width:767px) {
                width: 33.33%;
                text-align: center;
                font-size: 16px !important;
                line-height: 1.2 !important;
            }

            span {
                display: block;
                font-weight: bold;
                color: $light-blue;
                padding: 10px 50px;

                @media(max-width:767px) {
                    padding: 10px 15px;
                    height: 55px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }

            &.active {
                span {
                    background: $white;
                    color: $black;
                }
            }

            &:hover {
                cursor: pointer;
            }
        }
    }

    .tab-contents-main {
        background: $white;

        .singletabcontainer {
            display: none;

            &.active {
                display: block;
            }
        }
    }
}

.extendedformcontainerblock {
    @media(max-width:991px) {
        width: 100% !important;
    }

    .Form__MainBody {
        .Form__Element:not(.FormSubmitButton) {
            display: inline-block;
            width: 100%;
        }

        .FormTextbox {
            @media(min-width:992px) {
                &:not(.FormTextbox--Textarea) {
                    float: left;
                    width: 50%;
                }
            }
        }

        .Form__Element {
            padding-right: 15px;

            label, input, textarea {
                width: 100%;
            }

            input, textarea {
                border-radius: 0;
                border: 1.5px solid $border-gray;
                margin: 0;
                line-height: 1.5;
                @include bodytext;
                -webkit-appearance: none;
                box-shadow: none;
                outline: none;
            }

            textarea {
                min-height: 140px;
            }

            label {
                color: $black;
                line-height: 1.3;
                @include bodytext;
                margin-bottom: 2px;
            }

            &.FormChoice {
                .Form__Element__Caption {
                    color: $black;
                    line-height: 1.3;
                    @include bodytext;
                    margin-bottom: 10px;
                }

                div {
                    float: left;
                    width: auto;
                    display: inline-flex;
                    align-items: center;
                    margin-right: 25px;

                    input[type='radio'] {
                        height: 17px;
                        width: 24px;
                        border: 1px solid $light-blue;
                        border-radius: 50%;
                        color: $light-blue;
                        -webkit-appearance: none;
                        padding: 2px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background: $white;
                        margin-right: 5px;

                        &:checked {
                            &:after {
                                content: '';
                                position: relative;
                                height: 12px;
                                width: 12px;
                                background: $light-blue;
                                border-radius: 50%;
                            }
                        }
                    }
                }
            }

            button[type='submit'] {
                border-radius: 10px 10px 0px 10px;
                padding: 0px 20px;
                border: 0;
                font-weight: 600 !important;
                line-height: 2;
                text-decoration: none;
                background: $light-blue;
                border: 2px solid $light-blue;
                color: $white !important;
                position: relative;
                display: inline-flex;
                align-items: center;

                img {
                    height: 28px;
                    width: 28px;
                    margin-left: 10px;
                }

                &:hover {
                    background: none;
                    border: 2px solid $light-blue;
                    color: $light-blue !important;
                }

                @media (max-width: 991px) {
                    padding: 5px 35px;
                }
            }
        }
    }
}

@media (min-width:1200px) and (max-width: 1499px) {
    .plan-grid {
        .plan-grid-item {
            width: 33.33%;
        }
    }
}

@media (min-width:768px) and (max-width: 1199px) {
    .plan-grid {
        .plan-grid-item {
            width: 50%;
        }
    }
}

@media (max-width: 767px) {
    .plan-grid {
        .plan-grid-item {
            width: 100%;
        }
    }
}

.contact-page-top {
    background-color: $lighter-gray !important;
    background-size: contain !important;
    background-position: top right !important;
}

.contact-page-tabs {
    position: relative;

    &:before {
        content: '';
        background: $lighter-gray;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: -1;
        height: 145px;

        @media (max-width:992px) {
            height: 250px;
        }
    }
}

.tabs {
    margin-top: 30px;

    ul {
        margin: 0;
        padding: 0;

        li {
            list-style-type: none;
            display: inline-block;
            padding: 10px 30px;
            margin-right: 15px;
            cursor: pointer;
            @include bodytext;
            font-weight: bold;

            &.active {
                background: $white;
                color: $light-blue;
            }

            @media (max-width:992px) {
                padding: 15px;
                width: 100%;
                border-bottom: 1px solid pink;
                background: $white;

                &.active {
                    background: $light-blue;
                    color: $white;
                }
            }
        }
    }
}

.gray-container {
    background: #f2f2f2;
    padding: 30px 0;
}

.tabs-content {
    .tab-content {
        display: none;

        &.active {
            display: inline-flex;
        }
    }
}

.block.iconcardblock {
    @media(min-width:768px) and (max-width:991px) {
        width: 50% !important;
        flex: 0 0 50% !important;
        max-width: 50% !important;
    }

    @media(max-width:767px) {
        width: 100% !important;
        flex: 0 0 100% !important;
        max-width: 100% !important;
    }
}

.grid-block-wrapper {
    @media (min-width:992px) {
        padding: 0 7%;
        margin-top: 30px;
    }

    @media (max-width:767px) {
        margin-top: 5px;
        margin-top: 15px;
    }

    h1 {
        color: $primary-blue;
    }

    h4 {
        color: $black;
        font-weight: 600;
        text-transform: none;
    }

    .current-address {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;

        b {
            margin: 0 10px;
        }

        p {
            margin: 0;

            @media (max-width:767px) {
                margin: 0 0 5px;
            }
        }

        .btn {
            @include bodytext;
            line-height: 1.6;
            padding-right: 20px;
            background: $primary-blue;
            border-color: $primary-blue;
            padding: 0 15px;


            @media (max-width:767px) {
                padding: 0 15px;
            }
        }
    }

    .zip-c-block {
        margin-bottom: 0px;

        form {
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: center;
            align-items: center;

            p {
                @include h3size;
                margin: 0;
            }

            @media (max-width:767px) {
                flex-direction: column;
            }
        }

        input {
            border: none;
            outline: none;
            width: 75px;
            margin: 0 5px 0 15px !important;
            padding: 0 5px;
            border-bottom: 2px solid $black;
            margin: 0 5px;
            @include bodytext;
            text-align: center;
        }

        .btn {
            @include bodytext;
            line-height: 1.6;
            padding-right: 20px;
            background: $primary-blue;
            border-color: $primary-blue;

            @media (max-width:767px) {
                padding: 0 15px;
            }
        }
    }
}

.valuescontainer {
    padding: 70px 0;

    @media (max-width:767px) {
        padding: 25px 0;
    }

    h1 {
        text-align: center;
        margin: 15px 0 50px;

        @media (max-width:767px) {
            margin: 15px 0;
        }
    }



    @media(max-width:991px) {
        .values-container {
            padding: 0 15px;
        }
    }

    .valueblock {
        margin-bottom: 30px;
        position: relative;

        @media (max-width:767px) {
            width: 100% !important;
            margin-bottom: 0;
            padding: 15px 0 0;
            border-top: 2px solid #F0F0F0;

            &:first-child {
                border: none;
            }

            img {
                margin: 0 0 10px;
                max-height: 60px;
            }
        }

        @media(max-width:991px) {
            .value-block {
                padding: 0 15px;
            }
        }

        @media (min-width:768px) {
            .value-block {
                border: 3px solid #F0F0F0;
                border-radius: 30px;
                position: relative;
                padding: 30px;
                height: 100%;
            }

            &:nth-child(1), &:nth-child(3) {
                .value-block {
                    padding-right: 120px;
                    padding-left: 15px;
                }
            }

            &:nth-child(2), &:nth-child(4) {
                .value-block {
                    padding-left: 120px;
                    padding-right: 15px;
                }
            }

            &:nth-child(1) {
                &:before {
                    height: 3px;
                    width: 95%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    background: $white;
                    content: '';
                    z-index: 2;
                }

                &:after {
                    width: 3px;
                    height: 95%;
                    position: absolute;
                    top: 0;
                    left: 12px;
                    background: $white;
                    content: '';
                    z-index: 2;
                }

                .value-block {
                    position: relative;

                    &:before {
                        height: 20px;
                        width: 20px;
                        position: absolute;
                        top: 0;
                        left: 0;
                        background: $white;
                        content: '';
                        z-index: 2;
                    }

                    border-bottom-right-radius: 0;

                    img {
                        bottom: 10px;
                        right: 10px
                    }
                }
            }

            &:nth-child(2) {
                &:before {
                    height: 3px;
                    width: 95%;
                    position: absolute;
                    top: 0;
                    right: 0;
                    background: $white;
                    content: '';
                    z-index: 2;
                }

                &:after {
                    width: 3px;
                    height: 95%;
                    position: absolute;
                    top: 0;
                    right: 12px;
                    background: $white;
                    content: '';
                    z-index: 2;
                }

                .value-block {
                    position: relative;

                    &:before {
                        height: 20px;
                        width: 20px;
                        position: absolute;
                        top: 0;
                        right: 0;
                        background: $white;
                        content: '';
                        z-index: 2;
                    }

                    border-bottom-left-radius: 0;

                    img {
                        bottom: 10px;
                        left: 10px
                    }
                }
            }

            &:nth-child(3) {
                &:before {
                    height: 3px;
                    width: 95%;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    background: $white;
                    content: '';
                    z-index: 2;
                }

                &:after {
                    width: 3px;
                    height: 95%;
                    position: absolute;
                    bottom: 0;
                    left: 12px;
                    background: $white;
                    content: '';
                    z-index: 2;
                }

                .value-block {
                    position: relative;

                    &:before {
                        height: 20px;
                        width: 20px;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        background: $white;
                        content: '';
                        z-index: 2;
                    }

                    border-top-right-radius: 0;

                    img {
                        top: 10px;
                        right: 10px
                    }
                }
            }

            &:nth-child(4) {
                &:before {
                    height: 3px;
                    width: 95%;
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    background: $white;
                    content: '';
                    z-index: 2;
                }

                &:after {
                    width: 3px;
                    height: 95%;
                    position: absolute;
                    bottom: 0;
                    right: 12px;
                    background: $white;
                    content: '';
                    z-index: 2;
                }

                .value-block {
                    position: relative;

                    &:before {
                        height: 20px;
                        width: 20px;
                        position: absolute;
                        bottom: 0;
                        right: 0;
                        background: $white;
                        content: '';
                        z-index: 2;
                    }

                    border-top-left-radius: 0;

                    img {
                        top: 10px;
                        left: 10px
                    }
                }
            }

            img {
                max-height: 90px;
                position: absolute;
            }
        }
    }
}

.leadershipcontainer {
    background: $primary-blue;
    padding: 70px 0;

    *:not(h1) {
        color: $white;
    }

    .leadership-intro {
        margin: 0 0 50px;

        @media (max-width:767px) {
            margin: 0;
        }
    }

    .leaderblock {
        @media (max-width:767px) {
            width: 100% !important;
        }

        margin: 15px 0 25px;

        img {
            width: 100%;
        }

        .leader-info {
            h3 {
                margin: 0;
            }
        }
    }

    .leaders-row {
        @media(min-width:992px) {
            .leaderblock {
                &:nth-child(odd) {
                    .leaders-block {
                        padding-right: 55px;
                    }
                }

                &:nth-child(even) {
                    .leaders-block {
                        padding-left: 55px;
                    }
                }
            }
        }
    }
}

.two-col-block {
    padding: 50px 0;

    &.gray-background {
        background: $light-gray;
    }

    * {
        color: $black;
    }
}

.image-bottom-mobile {
    @media(max-width:767px) {
        flex-direction: column-reverse !important;
        padding: 30px 0 0;

        img {
            margin: 30px 0 -2px;
        }
    }
}

.plan-detail-wrapper {
    padding: 80px 0 50px;
    position: relative;
    overflow: hidden;

    @media (min-width:1200px) {
        background: none !important;
    }

    @media (min-width:768px) and (max-width:1199px) {
        background-size: cover !important;
        background-position-y: 180px !important;
    }

    @media(max-width:767px) {
        background: none !important;
        padding: 0 0 15px;
    }

    .img-bg {
        position: absolute;
        top: 300px;
        left: 0;
        right: 0;
        width: 100%;
        z-index: 0;

        @media (min-width:1921px) {
            top: 230px;
        }

        @media (max-width:1199px) {
            display: none;
        }
    }

    .container {
        position: relative;
        z-index: 1;

        &.top-half {
            @media(min-width:768px) {
                background: none !important;
            }

            @media(max-width:767px) {
                padding: 25px 15px;
                background-size: 230% !important;
                background-position-y: 190px !important;
                background-position-x: right !important;
                margin: 0 0 25px;

                .row {
                    flex-direction: column-reverse;
                }

                h1, p {
                    color: $white;
                }
            }

            @media(max-width:450px) {
                background-position-y: 180px !important;
                background-size: 270% !important;
            }
        }
    }

    .plan-price-wrap {
        height: 400px;
        background-size: contain !important;

        @media(max-width:767px) {
            height: 270px;
        }
    }

    h2 {
        color: $white;

        @media (max-width:767px) {
            color: $black;
        }
    }

    .feature {
        background: $primary-blue;
        padding: 40px 30px;
        border-radius: 25px 25px 0px 25px;
        height: 100%;

        @media (max-width:1199px) {
            //margin: 0 0 20px;
        }

        img {
            margin: 0 0 15px;
            max-height: 80px;
        }
    }

    .plan-price-wrap {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        align-items: center;
        flex-direction: column;

        .plan-price {
            display: inline-block;
            width: 100%;
            font-family: 'Poppins';
            font-size: 112px;
            color: $primary-blue;
            text-align: center;
            padding: 10px 0 30px;
            line-height: 80px;

            sup {
                font-size: 48px;
                top: 0;
                vertical-align: top;
            }

            @media(max-width:767px) {
                font-size: 80px;
                line-height: 50px;

                sup {
                    font-size: 36px;
                }
            }
        }

        .plan-per {
            display: inline-block;
            width: 100%;
            text-align: center;
            font-size: 30px;
            line-height: 20px;
            color: $primary-blue;

            @media(max-width:767px) {
                margin: 0;
                font-size: 20px;
            }
        }

        .plan-location {
            display: inline-block;
            width: 100%;
            text-align: center;
            font-size: 18px;
            line-height: 24px;
            color: $primary-blue;
            margin: 10px 0 0;

            @media(max-width:767px) {
                margin: 0;
                font-size: 16px;
            }
        }
    }
}

.documents-wrap {
    div:first-child {
        .documnet-wrapper {
            padding: 0px;
            border-left: none;

            @media(max-width:767px) {
                padding: 25px 0 10px;
                border-top: none;
            }
        }
    }

    .documnet-wrapper {
        padding: 0px 10px 0 50px;
        border-left: 2px solid #f5f5f5;

        @media (min-width:768px) and (max-width:1199px) {
            padding: 0px 0px 0 20px;

            img {
                float: right;
                max-width: 40px;
            }
        }

        @media(max-width:767px) {
            padding: 25px 0 10px;
            border-top: 2px solid #f5f5f5;
            border-left: none;
        }
    }
}

.address-modal-header {
    display: inline-flex;
    width: 100%;

    .icon-wrapper {
        max-width: 60px;

        img {
            width: 60px;
            height: auto;
        }
    }
}

.emaillistsignupblock {
    .js-newsletter-sign-up.needs-validation {
        width: 100% !important;
        align-items: center;
        display: inline-flex;
        justify-content: center;
        padding: 10px 0 50px;
        flex-direction: column;
        align-items: center;
        text-align: center;

        .invalid-feedback {
            margin: 15px 0 0;
        }

        .sign-up-wrapper {
            max-width: 500px;
        }

        input::placeholder {
            text-align: center;
        }

        button[type="submit"] {
            border-radius: 10px 10px 0px 10px;
            @include bodytext;
            color: $white;
            padding: 0px 10px;
            border: 0;
            font-weight: 600 !important;
            line-height: 2;
            text-decoration: none;
            background: $light-blue;
            border: 2px solid $light-blue;
            color: $white !important;
            position: relative;
            width: 200px;
            display: inline-flex;
            align-items: center;

            &:before {
                content: 'Sign Up';
                position: relative;
            }

            img {
                height: 30px;
                width: 30px;
                margin-left: 10px;
                display: block;
            }

            &:hover {
                background: none;
                border: 2px solid $light-blue;
                color: $light-blue !important;
            }

            span {
                display: none;
            }
        }
    }
}

.breadcrumbs {
    a {
        color: $light-blue;
        text-decoration: none;
    }

    i {
        color: $black;
        font-size: 12px;
        margin: 0 3px;
    }

    span {
        color: $black;
        @include bodytext;
    }
}

.blog-individual {
    .blog-thumb {
        background-size: cover !important;
        width: 100%;
        height: 350px;
        margin: 0 0 15px;
    }

    .blog-date {
        margin: 0 0 15px;
    }

    p {
        margin: 0 0 10px;
    }

    .categories {
        margin: 0 0 20px;
    }

    .read-more {
        font-weight: bold;
        color: $light-blue;
        text-decoration: none;

        i {
            margin-left: 0px;
            font-size: 10px;
        }
    }
}

.categories-mobile {
    span {
        padding: 10px;
        text-align: center;
        background: $light-gray;
        color: $light-blue;
        font-weight: bold;
        display: inline-block;
        width: 100%;

        i {
            font-size: 10px;
            transform: rotate(90deg);
            display: inline-block;
            margin-left: 5px;
        }
    }

    ul {
        margin: 10px 0 !important;
        padding: 0 !important;

        li {
            padding: 5px 10px;
            margin: 0;
            list-style-type: none;
            border-top: 1px solid #cfcfcf;

            &:first-child {
                border: none;
            }

            a {
                color: $light-blue;
                text-decoration: none;
            }
        }
    }
}

.categories-main {
    ul {
        margin: 10px 0 !important;
        padding: 0 !important;

        li {
            padding: 5px 0;
            margin: 0;
            list-style-type: none;
            border-top: 1px solid #cfcfcf;

            &:first-child {
                border: none;
            }

            a {
                color: $light-blue;
                text-decoration: none;
            }

            &.active a {
                font-weight: bold;
            }
        }
    }
}

.recent-posts {
    .recent-post {
        margin: 0 0 25px;

        a {
            color: $light-blue;
            text-decoration: none;

            &:hover {
                color: $primary-blue;
            }
        }
    }
}

.blog-detail-wrapper {
    img {
        width: 100%;
        margin: 0 0 20px;
    }
}

.verticalcontentlistblock {
    @at-root div#content #{&} {
        p, a, ul li, ol li {
            font-size: 20px;
            line-height: 25px;
            color: $black;
        }

        p, ul li, ol li {
            margin-bottom: 12px;
        }

        ul li, ol li {
            @include textUnderlineCustom;
        }

        .content-block-text {
            .content-block-wrap {
                .verticalcontentlistblock-header {
                    //p {font-size:20px; line-height:25px; color:$black;}
                }
            }
        }
    }

    .content-block-text {
        .content-block-wrap {
            padding-top: 16px;

            .verticalcontentlistblock-header {
                h2 {
                    margin-bottom: 12px;
                    font-size: 30px;
                    line-height: 35px;
                }

                p {
                    margin-bottom: 12px;
                    font-size: 20px;
                    line-height: 25px;
                    color: $black;
                }
            }
        }
    }

    .iconcardblocklist {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;

        .iconcardblock {
            width: 100%;

            &:nth-child(even) {
                background: $gray-fa;
            }
        }

        .icon-wrapper {
            margin-bottom: 6px;
        }

        .icon-text-block {
            img {
                height: 70px;
            }
        }

        .icon-text-block-header {
            margin-bottom: 6px;

            h4 {
                margin-bottom: 12px;
                font-size: 30px;
                line-height: 35px;
            }
        }
    }

    @media all and (min-width:$screen_md_min) {
        & {
            .iconcardblocklist {
                .iconcardblock {
                    background: $gray-fa;

                    &:nth-child(4n+1), &:nth-child(4n) {
                        background: none;
                    }
                }
            }
        }
    }

    @media all and (min-width:$screen_lg_min) {
        & {
            @at-root div#content #{&} {
                p, a, ul li, ol li {
                    font-size: 25px;
                    line-height: 35px;
                }

                .content-block-text {
                    .content-block-wrap {
                        .verticalcontentlistblock-header {
                            p {
                                margin-bottom: 0;
                                font-size: 30px;
                                line-height: 35px;
                            }
                        }
                    }
                }
            }

            .content-block-text {
                .content-block-wrap {
                    padding-top: 30px;

                    .verticalcontentlistblock-header {
                        h2 {
                            margin-bottom: 30px;
                            font-size: 55px;
                            line-height: 100%;
                        }
                    }
                }
            }

            .iconcardblocklist {
                .iconcardblock {
                    background: none;

                    &:nth-child(4n+1), &:nth-child(4n) {
                        background: none;
                    }

                    &:nth-child(even) {
                        background: $gray-fa;
                    }
                }

                .icon-text-block {
                    img {
                        height: 80px;
                    }
                }

                .icon-text-block-header { //margin-bottom:6px;
                    h4 {
                        margin-bottom: 12px;
                        font-size: 40px;
                        line-height: 50px;
                    }
                }
            }
        }
    }
}
