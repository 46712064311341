﻿
.account-add-property {
    .zip-code-block-main {
        padding: 0;
        background: none;

        .row .text-md-center {
            text-align: left !important;
        }

        h1 {
            font-size: 1.9em;
            color: #000000 !important;
            font-family: "Raleway", sans-serif;
            line-height: 30px !important;
            font-weight: normal;
        }

        .input-wrapper {
            text-align: left !important;

            input {
                border: solid 1px #ddd;
                color: black;
            }
        }
    }

    #AddressLoader {
        color: $light-blue;

        .loading-message {
            font-weight: bold;
            padding-left: 12px;
        }
    }
}

.my-account .plan-grid {
    .plan-grid-item {
        width: 33.33%;
    }
}
.my-account__order-summary .edit-field {
    a {
        color: $light-blue;
        border-bottom: solid 1px $light-blue;
    }
}