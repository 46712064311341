﻿// Responsive breakpoints
$screen_xxl: 1440px !default;
$screen_xxl_min: $screen_xxl !default;

$screen_xl: 1200px !default;
$screen_xl_min: $screen_xl !default;
$screen_xl_max: ($screen_xxl_min - 1) !default;

$screen_lg: 992px !default;
$screen_lg_min: $screen_lg !default;
$screen_lg_max: ($screen_xl_min - 1) !default;

$screen_md: 768px !default;
$screen_md_min: $screen_md !default;
$screen_md_max: ($screen_lg_min - 1) !default;

$screen_sm: 576px !default;
$screen_sm_min: $screen_sm !default;
$screen_sm_max: ($screen_md_min - 1) !default;

$screen_xs: 0px !default;
$screen_xs_min: $screen_xs !default;
$screen_xs_max: ($screen_sm_min - 1) !default;

// Color definitions
$black: #000000;
$white: #ffffff;

// Site colors
$primary: #999999;
$primary-hover: #999999;

$primary-blue: #102247;
$primary-blue-trans: rgba(0,35,101,.90);
$border-blue: #001A4B;
$light-blue: #156DEB;
$light-pink: rgba(196,102,179,.28);
$light-pink-alt: rgba(201,117,186,.39);
$yellow: #DBA700;
$lighter-yellow:#FDCB2A;
$dark-gray: #888888;
$medium-gray: #525552;
$light-gray: #f2f2f2;
$lighter-gray: #f0f0f0;
$lighter-gray-2: #f9f9f9;
$medium-gray: #cccccc;
$border-gray: #bababa;
$white: #ffffff;
$green: #5AB587;
$error-red: #F40C0C;
$purple: #63425d;
$purple-light: #b190ab;
$black: #000000;
$gray-fa:#fafafa;
$readonly-input-bkg: #f5f5f5;

// Mixins
@mixin focusOutline {
    &:focus {
        outline: dashed;
        outline-width: 1px;
        outline-color: #999999;
        outline-offset: -1px;
    }
}
// Font Sizes:
@mixin h1size {
    font-size: 44px;
    @media (max-width: 991px) {
        font-size: 30px;
        line-height: 1;
    }
}
@mixin h2size {
    font-size: 40px;

    @media (max-width: 991px) {
        font-size: 26px;
        line-height: 1.2;
    }
}
@mixin h3size {
    font-size: 24px;

    @media (max-width: 991px) {
        font-size: 22px;
        line-height: 1.2;
    }
}
@mixin h4size {
    font-size: 20px;

    @media (max-width: 991px) {
        font-weight: normal;
        font-size: 18px;
        line-height: 1.2;
    }
}
@mixin buttonText {
    font-size: 20px;
    line-height: 30px;
}
@mixin bodytext {
    font-size: 20px;
    line-height: 30px;

    @media (max-width: 991px) {
        font-size: 18px;
        line-height: 1.5;
    }
}
@mixin bodytext-sm {
    font-size: 18px;
    line-height: 27px;

    @media (max-width: 991px) {
        font-size: 16px;
        line-height: 24px;
    }
}

@mixin textUnderlineCustom($offset:0.2em, $thickness:1px, $style:solid, $line:underline, $color:currentcolor) {
    text-underline-offset:$offset;
    text-decoration-thickness: $thickness;
    text-decoration-line: $line;
    text-decoration-style: $style;
    text-decoration-color: $color;
}