﻿@import "Variables.scss";

header.account-header {
    .header-row {
        .row {
            --bs-gutter-x: 3.75rem;
        }
    }

    .mobile-menu-btn {
        span {
            color: $light-blue;
        }

        &.menu-opened {
            span {
                color: $light-blue;
            }

            &:after {
                border-color: transparent transparent $primary-blue transparent;
            }
        }
    }

    .top-row {
        ul {
            li {
                &:last-child {
                    margin: 0 0 0 10px;

                    &:after {
                        display: none;
                    }
                }

                span {
                    margin-right: 3px;
                }
            }
        }
    }

    .multiple-addresses {
        text-align: right;

        .select-wrapper {
            position: relative;
            margin-right: -30px;

            &:before {
                content: '';
                height: 5px;
                background: $white;
                position: absolute;
                bottom: 0;
                right: 0;
                width: 30px;
            }

            &:after {
                font-family: 'de' !important;
                content: "\e905";
                color: $light-blue;
                font-size: 20px;
                position: absolute;
                top: 5px;
                right: 5px;
                transform: rotate(90deg);
                pointer-events: none;
            }
        }

        select {
            padding: 0 30px 0 10px;
            border: none;
            border-bottom: 3px solid $primary-blue;
            @include bodytext-sm;
            font-weight: bold;
            color: $primary-blue;
            -webkit-appearance: none;
            font-family: 'Poppins';
            background: none;

            option {
                @include bodytext-sm;
                font-weight: regular;
                font-weight: $primary-blue;
            }
        }
    }

    .alt-menu a {
        color: $light-blue;
        text-decoration: none;
    }

    .navbar {
        position: unset;
        padding: 0;

        a {
            font-weight: bold;
            @include bodytext;
            color: $primary-blue;
            border-bottom: 2px solid $white;

            &:hover {
                text-decoration: none;
                border-bottom: 2px solid $light-blue;
                color: $light-blue;
            }
        }

        .mobile-nav {
            display: none;

            &.open {
                display: block;
            }
        }

        &.alt-menu {
            .navbar-nav {
                a {
                    @include bodytext-sm;
                    padding: 10px 20px;
                    border-left: 3px solid $white;
                    border-bottom: 0;
                    display: block;
                    float: left;
                    color: $primary-blue;

                    &.active, &:hover {
                        text-decoration: none;
                        border-left: 3px solid $light-blue;
                    }
                }
            }

            .mobile-nav {
                &.open {
                    top: 100%;
                    right: 0;
                    margin-top: 13px;
                }

                .navbar-nav {
                    flex-direction: column;

                    li {
                        display: inline-block;
                        width: 100%;
                    }
                }
            }
        }
    }
}

.mobile-my-account {
    background: $lighter-gray;
    padding: 15px 0;

    .multiple-addresses {
        text-align: right;

        .select-wrapper {
            position: relative;

            &:after {
                font-family: 'de' !important;
                content: "\e905";
                color: $light-blue;
                font-size: 20px;
                position: absolute;
                top: 7px;
                right: -23px;
                transform: rotate(90deg);

                @media (max-width:991px) {
                    font-size: 15px;
                }
            }
        }

        select {
            padding: 0 0 0 10px;
            border: none;
            border-bottom: 3px solid $primary-blue;
            @include bodytext-sm;
            font-weight: bold;
            color: $primary-blue;
            -webkit-appearance: none;
            font-family: 'Poppins';
            background: none;

            @media (max-width:991px) {
                padding: 0 10px 0 10px;
                width: 155px;
            }

            option {
                @include bodytext-sm;
                font-weight: regular;
                font-weight: $primary-blue;
            }
        }
    }

    .account-number {
        text-align: right;
    }
}

header.account-header, .mobile-my-account {
    .account-info-row {
        --bs-gutter-x: 1.5rem;
        margin-right: calc(-0.5 * var(--bs-gutter-x));
        margin-left: calc(-0.5 * var(--bs-gutter-x));

        & > div {
            padding-right: calc(var(--bs-gutter-x) * 0.5);
            padding-left: calc(var(--bs-gutter-x) * 0.5);
        }

        @media all and (min-width:$screen_lg_min) {
            & {
                --bs-gutter-x: 3.75rem;
            }
        }
    }

    .multiple-addresses {
        height: 48px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        span {
            color: $primary-blue;
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
            text-align: right;

            &.multiple-addresses-header {
                height: 24px;
                transition: opacity 0.2s ease, height 0.2s linear;
            }
        }

        .service-address {
            font-size: 18px;
            line-height: 24px;
            font-weight: bold;
            color: $primary-blue;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            &.service-address-hidden {
                transition: opacity 0.2s ease, height 0.2s linear;
                opacity: 0;
                height: 0;
            }
        }

        &:hover, &:focus, &:focus-within {
            & > span.multiple-addresses-header {
                opacity: 0;
                height: 0;
            }

            .service-address {
                &.service-address-hidden {
                    opacity: 1;
                    height: 24px;
                }
            }
        }
    }

    .account-number {
        text-align: right;

        span {
            color: $primary-blue;
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
            text-align: right;
        }

        .acc-number {
            font-size: 18px;
            line-height: 24px;
            color: $primary-blue;
            font-weight: bold;
            font-family: 'Poppins';
        }
    }

    .account-switch {
        * {
            transition: none;
        }
    }

    .account-switch-dropdown {
        padding-top: 2px;
        width: 470px;
        max-width: 100%;
        z-index: 9;
    }

    .account-switch-dropdown-ear {
        &::after {
            content: "";
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 0 12px 12px;
            border-color: transparent transparent $primary-blue transparent;
        }
    }

    .account-switch-body {
        background: $white;
        border: 1px solid rgba($black, 0.02);
        box-shadow: 0 0 5px rgba($black, 0.16);
        max-height: 70vh;
        overflow-y: scroll;

        .account-switch-property {
            padding: 16px 15px 13px 20px;
            border: 0;
            border-top: 2px solid $primary;
            color: $black;
            transition: none;
            width: 100%;
            text-align: left;
            background: $white;

            &.selected {
                background: $white;
                color: $black;
            }

            &:hover, &:focus, &:focus-within {
                background: $light-blue;
                color: $white;
                transition: none;
            }

            &.account-active {
                background: $lighter-gray;
                color: $black;

                &:hover, &:focus, &:focus-within {
                    color: $black;
                    cursor: not-allowed;
                }
            }
        }

        .account-switch-property-notification {
            padding-left: 12px;

            img {
                width: 25px;
            }
        }

        form:first-of-type {
            .account-switch-property {
                border-top: 0;
            }
        }

        .account-pending {
            text-decoration: none;

            .account-switch-property {
                color: $dark-gray;

                .fst-italic {
                    color: $black;
                }
            }
        }
    }

    .account-switch-footer {
        border-top: 2px solid $primary;
        padding: 10px 25px 10px 30px;

        a {
            text-decoration: none;
        }

        &:hover {
            background: $light-blue;

            a {
                color: $white;
                font-weight: normal;
            }
        }
    }

    @media all and (min-width:$screen_lg_min) {
        .account-info-row {
            --bs-gutter-x: 2.25rem;
        }

        .multiple-addresses {
            height: 54px;

            span {
                color: $primary-blue;
                font-size: 18px;
                line-height: 27px;
                font-weight: 300;

                &.multiple-addresses-header {
                    height: 27px;
                    transition: opacity 0.2s ease, height 0.2s linear;
                }
            }

            .service-address {
                font-size: 24px;
                line-height: 27px;
                font-family: 'Poppins';

                &.service-address-hidden {
                    font-size: 18px;
                }
            }

            &:hover, &:focus, &:focus-within {
                .service-address {
                    &.service-address-hidden {
                        height: 27px;
                    }
                }
            }
        }

        .account-number {
            span {
                font-size: 18px;
                line-height: 27px;
                font-weight: 300;
            }

            .acc-number {
                font-size: 24px;
                line-height: 27px;
            }
        }

        .account-switch-dropdown {
            padding-top: 8px;
        }

        .account-switch-dropdown-ear {
            &::after {
                border-width: 0 0 16px 16px;
            }
        }

        .account-switch-body {
            max-height: 70vh;
            overflow-y: scroll;

            .account-switch-property {
                padding: 25px 25px 25px 30px;
                border-top: 2px solid $primary;
            }

            .account-switch-property-notification {
                img {
                    width: 33px;
                }
            }
        }
    }

    @media all and (min-width:$screen_xl_min) {
        .account-info-row {
            --bs-gutter-x: 3.75rem;
        }
    }
}

.account-banner {
    min-height: 280px;
    display: flex;
    align-items: center;
    position: relative;
    background-color: $primary-blue !important;

    .col-lg-9 {
        @media (min-width:1200px) {
            padding-left: 100px !important;
        }
    }

    &.account-internal-header {
        .col-lg-9 {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;

            img {
                margin-right: 15px;
            }

            @media (max-width:1199px) {
                flex-direction: column;
                flex-wrap: wrap;
                justify-content: center;

                img {
                    margin-right: 0;
                    margin-bottom: 10px;
                }
            }

            h1 {
                @media (max-width:991px) {
                    text-align: center;
                }
            }
        }
    }

    @media (max-width:991px) {
        background-position: center right !important;
        background-size: cover !important;

        &:after {
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: transparent linear-gradient(178deg, #102247D1 0%, #0000003C 100%) 0% 0% no-repeat padding-box;
            opacity: 0.83;
            z-index: 0;
        }

        .container {
            position: relative;
            z-index: 1;
        }
    }

    * {
        color: $yellow;
    }

    &.account-add-property {
        h1 {
            img {
                width: 44px;
                margin-right: 20px;
            }
        }

        @media all and (min-width:$screen_lg_min) {
            margin-top: 40px;

            & {
                h1 {
                    font-size: 55px;

                    img {
                        width: 66px;
                        margin-right: 28px;
                    }
                }
            }
        }
    }
}

.account-sidebar {
    background: $primary-blue;
    color: $white;
    @include bodytext;
    padding: 30px 20px;
    margin-top: -200px;
    z-index: 10;

    h1, h2, h3, h4, h5, h6, p {
        color: $white;
    }

    h3 {
        @include h3size;
    }

    a {
        @include bodytext;

        &.btn {
            text-decoration: none;
            @include bodytext;
            padding-top: 5px !important;
            padding-bottom: 5px !important;
        }

        color: $white;
        text-decoration: underline;

        &:hover {
            color: $light-blue;
        }
    }

    span {
        color: $medium-gray;
    }

    .manage-account-nav {
        margin: 50px 0 0;
    }

    ul {
        margin: 0;
        padding: 0;

        li {
            list-style-type: none;
            padding: 5px;
            position: relative;

            a {
                color: $white;
                text-decoration: none;
                @include bodytext;

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }

    .help-links {
        a {
            text-decoration: none;

            span {
                margin-right: 3px;
            }

            .question-icon {
                height: 25px;
                width: 25px;
                background: $white;
                border-radius: 50%;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                position: relative;

                &:before {
                    content: '?';
                    color: $primary-blue;
                    font-size: 22px;
                    line-height: 1;
                    font-weight: bold;
                    position: relative;
                }
            }

            &:hover {
                .question-icon {
                    background: $light-blue;
                }
            }
        }
    }
}

.account-content {
    padding: 100px 0px 0 90px;
    margin: 0 0 80px;

    @media (max-width:1199px) {
        padding: 20px 0px 0 0px;
        margin: 0;
    }

    ul {
        margin: 10px 0 15px;
        padding: 0;

        li {
            @include bodytext;
            color: #000000;
            font-family: 'Raleway', sans-serif;
            position: relative;
            list-style-type: none;
            padding-left: 20px;
            margin: 0 0 3px;

            &:before {
                content: '';
                height: 8px;
                width: 8px;
                background: $black;
                border-radius: 50%;
                position: absolute;
                top: 12px;
                left: 0;
            }
        }
    }

    .pre-cust-messaging {
        font-size: 20px
    }

    .gray-box {
        background: $lighter-gray;
        padding: 40px 20px;
        border-radius: 25px 25px 0px 25px;
        @include bodytext;
        color: $black;

        @media (max-width:991px) {
            padding: 0px;

            h3 {
                @include h3size;
            }
        }

        span a {
            @include bodytext;
        }

        .row {
            div[class^="col-"] {
                border-left: 1px solid $white;
                padding: 0 25px;

                @media (max-width:991px) {
                    border-left: none;
                    border-top: 1px solid $white;
                    padding: 25px;
                }

                &:first-child {
                    border: none;
                }

                &:nth-child(2) {
                    padding: 0 40px;

                    @media (max-width:991px) {
                        padding: 25px;
                    }
                }
            }
        }

        .amount-due, .month-usage, .bill-settings-heading {
            font-size: 30px;
            font-weight: bold;
            line-height: 1;
            color: $primary-blue;
            padding-top: 0;
            padding-bottom: 0;

            @media (max-width:991px) {
                font-size: 20px;
            }

            span {
                display: block;
                margin-top: 15px;
                font-size: 48px;
                overflow: hidden;

                @media (max-width:991px) {
                    font-size: 30px;
                }
            }
        }

        .due-date,
        .pending-payment {
            color: $primary-blue;
            margin: 0 0 15px;
        }
    }

    hr {
        margin: 30px 15px;
    }

    .icon-content-block {
        padding-right: 20px;
        margin: 0 0 25px;

        img {
            display: block;
            max-height: 80px;
            margin: 0 0 10px;
        }

        h3 {
            color: $black;
        }

        p, div {
            @include bodytext;
            color: $black;
            line-height: 1.3;
            display: inline-block;
            width: 100%;
        }

        a {
            color: $light-blue;
            @include bodytext;
            display: inline-block;
            margin-top: 15px;
            text-decoration: none;
            font-weight: bold;
            width: auto;
            padding-right: 15px;
            position: relative;

            &:after {
                font-family: 'de' !important;
                content: "\e905";
                color: $light-blue;
                font-size: 10px;
                position: absolute;
                top: 7px;
                right: 0;
            }

            @media (max-width:991px) {
                br {
                    display: none;
                }
            }
        }

        span {
            color: $medium-gray;
            @include bodytext;
            display: inline-block;
            margin-top: 15px;
            text-decoration: none;
            font-weight: bold;
            width: auto;
            padding-right: 15px;
            position: relative;

            @media (max-width:991px) {
                br {
                    display: none;
                }
            }
        }
    }

    &.account-information {
        h2 {
            @include h3size;
            color: $primary-blue;
            margin: 0;

            @media (max-width:991px) {
                color: $black;
                margin: 0 0 10px;
            }

            &.black {
                color: $black;
            }
        }

        h4, .address {
            font-weight: 500;
            color: $black;
            @include bodytext;
            margin: 0;

            @media (max-width:991px) {
                color: $black;
            }
        }

        .address {
            color: $dark-gray;
            font-family: "Poppins";
            font-weight: 300;

            @media (max-width:991px) {
                color: $dark-gray;
            }
        }

        i {
            color: $light-blue;
        }
    }

    h2 {
        font-size: 30px;
        line-height: 35px;
        color: $primary-blue;
        margin: 0;

        span {
            color: $medium-gray;
            @include bodytext;

            @media (max-width:991px) {
                color: $black;
            }
        }
    }

    h4, .address {
        font-weight: 500;
        color: $black;
        font-size: 25px;
        line-height: 35px;
        margin: 0;

        @media (max-width:991px) {
            color: $black;
            font-size: 20px;
        }
    }

    .address {
        color: $dark-gray;
        font-family: "Poppins";
        font-weight: 300;

        @media (max-width:991px) {
            color: $dark-gray;
        }
    }

    .edit-field {
        position: relative;
        margin: -5px 0 0;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding-right: 30px;

        span {
            font-size: 20px;
            line-height: 35px;
            font-family: 'Poppins';
            font-weight: 300;

            @media (max-width:991px) {
                color: $dark-gray;
                font-size: 20px;
            }
        }

        .address {
            color: $dark-gray;
            font-family: "Poppins";
            font-weight: 300;

            @media (max-width:991px) {
                color: $dark-gray;
            }
        }

        i {
            position: absolute;
            right: 0;
            top: 8px;
            font-size: 20px;
            cursor: pointer;

            &.de-edit {
                border-bottom: 1px solid $light-blue;
            }

            &.active {
                color: $dark-gray;

                &.de-edit {
                    border-bottom: 1px solid $light-blue;
                }
            }
        }
    }

    .edit-info {
        background: $lighter-gray;
        padding: 20px;
        margin: -10px 0 30px;
        display: none;

        &.open {
            display: block;
        }

        p {
            font-size: 20px;
            line-height: 30px;
            color: $black;
            margin: 0 0 15px;
        }
    }

    input {
        border-radius: 0;
        border-color: $border-gray;
        border-width: 1.5px;
        border-style: solid;
        margin: 0 0 15px;
        line-height: 1.5;
        font-size: 20px;
        padding: 3px 15px;
        -webkit-appearance: textarea;
        -moz-appearance: textfield;
        width: 100%;

        &.other-amount {
            padding-left: 25px;

            &:before {
                content: '$';
                font-size: 20px;
                color: $dark-gray;
                position: relative;
            }
        }
    }

    label {
        color: $black;
        line-height: 1.3;
        font-size: 20px;
        margin-bottom: 2px;

        @media (max-width: 991px) {
            font-size: 20px;
            line-height: 1.2;
        }
    }

    .dropdown-wrapper {
        border-radius: 0;
        position: relative;
        display: inline-block;
        width: 100%;

        &:before {
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 12px 8px 0 8px;
            border-color: #555954 transparent transparent transparent;
            position: absolute;
            right: 12px;
            top: 15px;
            pointer-events: none;
        }

        select {
            -webkit-appearance: none;
            border: 1.5px solid $border-gray;
            border-radius: 0;
            background: $white;
            line-height: 1.2;
            font-size: 20px;
            padding: .375rem .75rem;
            display: inline-block;
            width: 100%;

            &:disabled {
                background: #e9ecef;
            }
        }
    }

    .buttons-wrap {
        text-align: right;

        @media(max-width:767px) {
            flex-wrap: wrap;
        }

        .btn {
            display: inline-block;
            font-size: 20px;

            &:first-child {
                margin-right: 10px;
            }

            p {
                @include bodytext;
                color: $black;
                margin: 0 0 15px;
            }

            input {
                border-radius: 0;
                border-color: $border-gray;
                border-width: 1.5px;
                border-style: solid;
                margin: 0 0 15px;
                @include bodytext;
                padding: 3px 15px;
                -webkit-appearance: textarea;
                -moz-appearance: textfield;
                width: 100%;
            }

            label {
                color: $black;
                @include bodytext;
                margin-bottom: 2px;
            }

            .dropdown-wrapper {
                border-radius: 0;
                position: relative;
                display: inline-block;
                width: 100%;

                &:before {
                    content: '';
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 12px 8px 0 8px;
                    border-color: #555954 transparent transparent transparent;
                    position: absolute;
                    right: 12px;
                    top: 15px;
                    pointer-events: none;
                }

                select {
                    -webkit-appearance: none;
                    border: 1.5px solid $border-gray;
                    border-radius: 0;
                    background: $white;
                    @include bodytext;
                    padding: .375rem .75rem;
                    display: inline-block;
                    width: 100%;

                    &:disabled {
                        background: #e9ecef;
                    }
                }
            }

            .buttons-wrap {
                text-align: right;

                .btn {
                    display: inline-block;
                    font-size: 20px;

                    &:first-child {
                        margin-right: 10px;
                    }

                    &.gray {
                        background: $dark-gray;
                        border-color: $dark-gray;
                        color: $white;

                        &:hover {
                            color: $primary-blue;
                            background: $white;
                            border-color: $white;
                        }
                    }
                }
            }
        }
    }

    input[type='radio'] {
        height: 30px;
        width: 30px;
        min-width: 30px;
        border: 1px solid #525552;
        border-radius: 50%;
        color: #525552;
        -webkit-appearance: none;
        padding: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #525552;

        &:checked {
            &:after {
                content: '';
                position: relative;
                height: 20px;
                width: 20px;
                background: transparent linear-gradient(180deg, #FAFAFA 0%, var(--unnamed-color-888888) 100%) 0% 0% no-repeat padding-box;
                background: transparent linear-gradient(180deg, #FAFAFA 0%, #888888 100%) 0% 0% no-repeat padding-box;
                box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
                border-radius: 50%;
            }
        }
    }

    label {
        color: $black;
        @include bodytext;
        margin-bottom: 2px;
    }

    .radio-wrapper {
        display: flex;
        align-items: center;

        input {
            margin-right: 10px;
        }
    }
}

.table-wrapper {
    margin: 20px 0;
    font-family: "Poppins";

    .table-header {
        width: 100%;
        border-bottom: 1px solid $dark-gray;
    }

    .label-wrapper {
        @include bodytext;
        color: $black;
        display: inline-block;
        padding: 3px 0;
    }

    .table-row {
        border-bottom: 1px solid $dark-gray;

        @media(min-width:992px) {
            display: flex;
            flex-wrap: nowrap;
        }

        .table-col {
            @include bodytext;
            color: $black;
            padding: 3px 0;

            i {
                font-size: 20px;
            }
        }
    }

    &.authorized-user {
        .label-wrapper, .table-col {
            @media(min-width:992px) {
                &:first-child {
                    width: 35%;
                    text-align: left;
                }

                &:nth-child(2) {
                    width: 35%;
                    text-align: left;
                }

                width: 15%;
                text-align: center;
            }
        }

        .mobile-label {
            display: none;

            @media(max-width:991px) {
                display: inline-block;
                width: 85px;
            }
        }

        @media(max-width:991px) {
            .table-col {
                display: inline-block;
                width: auto;
                padding-right: 25px;

                &:first-child, &:nth-child(2) {
                    width: 100%;
                    padding-right: 0;
                }
            }
        }

        .mobile-label {
            font-weight: bold;
        }
    }

    &.current-payment-methods {
        margin: 0 0 20px;

        .label-wrapper, .table-col {
            @media(min-width:992px) {
                &:first-child {
                    width: 30%;
                    text-align: left;
                }

                &:nth-child(2) {
                    width: 20%;
                    text-align: right;
                }

                &:nth-child(3), &:nth-child(4) {
                    text-align: right;
                }

                width: 12.5%;
                text-align: center;
            }
        }

        .mobile-label {
            font-weight: normal;
            display: none;

            @media(max-width:991px) {
                font-weight: bold;
                display: inline-block;
                width: auto;
                margin-right: 5px;
            }
        }

        .table-col {
            font-weight: bold;

            @media(max-width:991px) {
                font-weight: normal;
                display: inline-block;
                width: auto;
                padding-right: 25px;

                &:first-child, &:nth-child(2), &:nth-child(3), &:nth-child(4) {
                    width: 100%;
                    padding-right: 0;
                }
            }
        }

        .table-row {
            @media(max-width:991px) {
                padding-bottom: 10px;
            }
        }
    }

    &.auto-pay, &.payment-methods {
        margin: 0 0 20px;

        .label-wrapper {
            @media(min-width:992px) {
                &:first-child {
                    width: 60px;
                    text-align: left;
                }

                &:nth-child(2) {
                    width: 50%;
                    text-align: left;
                }

                &:nth-child(3), &:nth-child(4) {
                    width: 23%;
                    text-align: right;
                }

                width: 12.5%;
                text-align: center;
            }
        }

        .table-col {
            &:first-child {
                width: 60px;
                max-width: 60px;
                text-align: left;

                @media(max-width:991px) {
                    width: 50px;
                    flex: 0 0 50px;
                }
            }
        }

        .mobile-col {
            display: inline-flex;
            flex-wrap: nowrap;
            width: 100%;

            @media(max-width:991px) {
                flex-wrap: wrap;
                width: 89%;
            }

            .table-col {
                box-sizing: border-box;

                @media(min-width:992px) {
                    &:first-child {
                        width: 52%;
                        text-align: left;
                        max-width: none;
                    }

                    &:nth-child(2) {
                        width: 25%;
                        text-align: right;
                    }

                    &:nth-child(3) {
                        width: 23%;
                        text-align: right;
                    }
                }

                @media(max-width:991px) {
                    &:first-child, &:nth-child(2), &:nth-child(3) {
                        width: 100%;
                        max-width: none;
                        text-align: left;
                        display: inline-flex;
                        flex-wrap: nowrap;

                        label {
                            text-overflow: ellipsis;
                            overflow: hidden !important;
                            width: 100%;
                            white-space: nowrap;
                        }
                    }

                    &:first-child {
                        .mobile-label {
                            flex: 0 0 140px;
                            width: 140px;
                            display: block;
                            float: left;
                        }
                    }
                }
            }
        }

        .mobile-label {
            font-weight: normal;
            display: none;

            @media(max-width:991px) {
                font-weight: bold;
                display: inline-block;
                width: auto;
                margin-right: 5px;
            }
        }

        input {
            margin: 0;
        }

        .table-col {
            font-weight: normal;

            label {
                font-weight: normal;
            }
        }

        .table-row {
            @media(max-width:991px) {
                padding: 10px 0;
                display: inline-flex;
                width: 100%;

                .table-col {
                    &:nth-child(2), &:nth-child(3), &:nth-child(4) {
                        align-self: 1;
                    }
                }
            }
        }
    }

    i {
        &.de-edit {
            border-bottom: 1px solid $light-blue;
        }

        &.active {
            color: $dark-gray;

            &.de-edit {
                border-bottom: 1px solid $light-blue;
            }
        }
    }
}

.auto-pay {
    .auto-pay-switch {
        @media(max-width:991px) {
            label {
                width: 100%;
            }
        }
    }

    .add-new {
        display: flex;
        align-items: center;

        i {
            font-size: 30px;
        }
    }
}

.payment-row {
    border-bottom: 1px solid $dark-gray;
    border-top: 1px solid $dark-gray;
    display: flex;
    flex-wrap: nowrap;

    @media(max-width:991px) {
        border-top: none;
        align-items: center;
    }

    .payment-info {
        width: 100%;

        @media(min-width:992px) {
            display: inline-flex;
            flex-wrap: nowrap;
        }
    }

    .payment-edit {
        width: 10%;
        text-align: right;
    }

    .payment-col {
        font-size: 20px;
        color: $black;
        padding: 3px 0;

        i {
            font-size: 20px;
        }

        @media(min-width:992px) {
            display: inline-block;

            &:first-child {
                width: 20%;
            }

            &:nth-child(2) {
                width: 50%;
            }

            &:last-child {
                width: 30%;
            }
        }
    }

    i {
        &.de-edit {
            border-bottom: 1px solid $light-blue;
            font-size: 20px;
            color: $light-blue;
            line-height: 30px;
        }

        &.active {
            color: $dark-gray;

            &.de-edit {
                border-bottom: 1px solid $light-blue;
            }
        }
    }
}

input.on-off-switch {
    border-radius: 30px;
    border: 2px solid #910107;
    width: 90px;
    background: #910107;
    height: 47px;
    position: relative;
    appearance: none;
    cursor: pointer;

    &:before {
        content: 'OFF';
        height: 39px;
        width: 39px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        color: $white;
        font-size: 14px;
        top: 2px;
        left: 2px;
        bottom: 2px;
        position: absolute;
        background: #F40C15;
    }

    &:checked {
        background: #006239;
        border: 2px solid #006239;

        &:before {
            content: 'ON';
            height: 39px;
            width: 39px;
            display: flex;
            border-radius: 50%;
            align-items: center;
            justify-content: center;
            color: $white;
            background: #00C471;
            font-size: 14px;
            top: 2px;
            right: 2px;
            left: auto;
            bottom: 2px;
            position: absolute;
        }
    }
}

.add-user {
    display: block;
    float: left;
    color: $light-blue;
    cursor: pointer;
    @include h3size;
    margin: 0 0 30px;

    &.active, &.active i {
        color: $dark-gray;
    }

    i {
        @include h3size;
        margin-right: 5px;
    }

    @media(max-width:991px) {
        display: flex;
        align-items: center;
        @include bodytext;
        font-weight: bold;
        margin: 0 0 15px;

        i {
            @include bodytext;
            margin-right: 5px;
        }
    }
}

.add-new {
    margin: 0;


    > a {
        text-decoration: none;
        color: $light-blue;
    }
}

.account-details {
    .customer-messages {
        display: inline-block;
        width: 100%;
        padding: 50px 0;
    }

    .content {
        max-width: 1340px;
        padding: 0 50px;

        @media (max-width: 991px) {
            padding: 0px 15px;
        }

        ul {
            margin: 15px 0;
            padding: 0;

            li {
                list-style-position: inside;
                list-style-type: none;
                @include bodytext;
                margin-bottom: 5px;

                &:before {
                    content: "\2022";
                    color: $light-blue;
                    font-size: 24px;
                    margin-right: 5px;
                }
            }
        }
    }
}

.bill-history {
    h1 {
        color: $primary-blue;
    }
}

.bill-settings {
    @include bodytext;
}

.billing-dropdown {
    position: relative;
    display: inline-block;
    width: 100%;

    @media(min-width:991px) {
        width: 119px;
        float: right;

        &:before {
            font-size: 14px;
            top: 12px;
        }
    }

    &:before {
        content: "\e905";
        font-family: 'de' !important;
        font-size: 16px;
        color: $light-blue;
        position: absolute;
        right: 15px;
        top: 10px;
        pointer-events: none;
        transform: rotate(90deg);
    }

    select {
        -webkit-appearance: none;
        border: 1.5px solid $dark-gray;
        background: $light-gray;
        color: #5D605C;
        border-radius: 0;
        line-height: 1.5;
        @include bodytext;
        padding: .375rem .75rem;
        display: inline-block;
        width: 100%;
    }
}

.bill-payment-history {
    .payment-row {
        width: 100%;
        display: inline-flex;
        flex-wrap: nowrap;
        border-bottom: 1.5px solid $dark-gray;
        @include bodytext;
        color: $black;
        padding: 5px 0;
        margin: 0;

        &:first-child {
            div {
                @media(max-width:767px) {
                    font-size: 14px;
                }
            }
        }

        @media(max-width:991px) {
            display: inline-flex;
            flex-wrap: wrap;
        }

        i {
            display: inline-block;
        }

        &:nth-child(2n+0) {
            background: $lighter-gray;
        }

        div {
            width: 33.33%;
            display: block;
            float: left;
            padding: 0 5px;
            margin: 0 0 10px;
            @include bodytext;

            @media(max-width:991px) {
                text-align: center;

                img {
                    margin-top: 5px;
                    height: 31px;
                }
            }

            @media(min-width:992px) {
                width: 20%;

                &:nth-child(2) {
                    width: 15% !important;
                }

                &:nth-child(3), &:nth-child(4) {
                    width: 12% !important;
                }

                &:nth-child(5), &:nth-child(6) {
                    width: 15% !important;
                    text-align: center;
                }

                &:nth-child(7) {
                    width: 10% !important;
                    text-align: center;
                }
            }
        }

        .mobile-accordion {
            width: 100%;
            margin: 0;

            .mobile-elements {
                padding: 0;
                width: 100%;
                text-align: left;
                display: none;
                margin: 0;

                div {
                    width: 100%;
                    text-align: left;
                    margin: 0;
                }
            }

            i {
                color: $dark-gray;
                transform: rotate(90deg);
            }

            &.open {
                .mobile-elements {
                    display: block;
                }

                i {
                    transform: rotate(270deg);
                }
            }
        }
    }
}

.pagination {
    a {
        width: 40px;
        height: 40px;
        border: 1.5px solid $light-blue;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $white;
        margin-right: 10px;
        @include bodytext;
        font-weight: bold;
        text-decoration: none;
        color: $light-blue;
        font-family: "Raleway";

        &.active {
            border-color: $light-gray;
            background: $light-gray;
        }

        i {
            font-size: 16px;
        }
    }
}

.current-plan-balance {
    @include bodytext;
    padding: 0 15px;
    color: $black;
    margin: 0 0 35px;

    .pl-0 {
        padding-left: 3px;
    }

    .pr-0 {
        padding-right: 3px;
    }

    .plan-row {
        padding: 5px 0;
        border-bottom: 1px solid $dark-gray;

        &:last-child {
            border: none;
            background: $lighter-gray;
        }

        &:nth-last-child(2) {
            background: $lighter-gray;
        }
    }
}

footer {
    &.account-footer {
        padding: 15px 0;
    }

    .social-links, .footer-nav-links {
        margin: 0;
        padding: 0;
        display: inline-block;
    }

    .social-links {
        a, span {
            color: $white;
        }

        @media (max-width:767px) {
            width: 100%;
        }
    }

    .copyright {
        @include bodytext;
    }

    .footer-nav-links {
        li {
            list-style-type: none;
            padding: 0 10px;
            border-right: 1px solid $dark-gray;
            display: inline;

            @media (max-width:767px) {
                &:first-child {
                    padding-left: 0;
                }

                &:nth-child(2) {
                    border: none;
                }
            }

            a {
                @include bodytext;
                color: $white;
                text-decoration: none;

                &:hover {
                    color: $light-blue;
                }
            }
        }
    }
}

.account-add-property {
    .plan-grid {
        margin-right: calc(-0.5 * var(--bs-gutter-x));
        margin-left: calc(-0.5 * var(--bs-gutter-x));

        .plan-grid-item {
            margin-bottom: 0;
            padding-top: 0;

            @media all and (min-width:$screen_xl_min) {
                & {
                    width: 33.33333333%;
                }
            }
        }
    }
}

.payment-and-billing-page {

    section.account-information {
        padding: 2rem 0 0 5.625rem;

        @media (max-width:$screen_lg_max) {
            padding: 1.25rem 0 0 0;
        }

        &.payment-methods {
            padding-top: 6.25rem;

            @media (max-width:$screen_lg_max) {
                padding-top: 1.25rem;
            }

            h3:first-of-type {
                margin-bottom: 1.5rem;
            }

            .add-new {
                margin-top: 0.5rem;

                i.de-add {
                    margin-right: 0.25rem;
                }
            }
        }

        &.paperless-billing {
            padding-bottom: 5rem;
        }

        &.auto-pay, &.paperless-billing {
            h3 {
                font-size: 1.25rem;
            }
        }

        .methods-table-loader {
            margin: 1.5rem 0;

            .spinner {
                width: 3rem;
                height: 3rem;
            }
        }

        .methods-table {
            display: flex;
            flex-direction: column;
            margin: 1.5rem 0;

            .methods-table-head {
                .methods-table-row {
                    display: grid;
                    font-weight: normal;
                    padding-top: 0;

                    @media(max-width:$screen_md_max) {
                        display: none;
                    }
                }
            }

            .methods-table-row {
                display: grid;
                grid-template-columns: 120px 3fr 1fr 1fr 1fr;
                border-bottom: 1px solid #888;
                font-weight: bold;
                padding: 0.25rem 0;
                column-gap: 0.5rem;

                @media(max-width:$screen_md_max) {
                    padding: 1rem 0;
                    grid-template-columns: 120px 3fr 1fr;
                    font-weight: normal;
                    row-gap: 0.25rem;
                }

                @media(max-width:$screen_sm_max) {
                    grid-template-columns: 120px auto;
                }

                i {
                    color: $light-blue;
                }

                input[type=radio] {
                    height: 20px;
                    width: 20px;
                    min-width: 20px;
                    border: 1px solid #525552;
                    border-radius: 50%;
                    color: #525552;
                    -webkit-appearance: none;
                    padding: 2px;
                    display: inline-flex;
                    vertical-align: middle;
                    justify-content: center;
                    align-items: center;
                    background: #525552;
                    cursor: pointer;

                    &:checked {
                        &:after {
                            content: '';
                            position: relative;
                            height: 12px;
                            width: 12px;
                            background: transparent linear-gradient(180deg, #FAFAFA 0%, var(--unnamed-color-888888) 100%) 0% 0% no-repeat padding-box;
                            background: transparent linear-gradient(180deg, #FAFAFA 0%, #888888 100%) 0% 0% no-repeat padding-box;
                            box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
                            border-radius: 50%;
                        }
                    }
                }

                .default-method {
                    vertical-align: middle;
                    padding: 0.25rem 0.35rem;
                    text-transform: uppercase;
                    background-color: $yellow;
                    color: $white;
                    border-radius: 5px;
                    font-weight: bold;
                    font-size: 0.825rem;
                    line-height: 20px;
                    margin-left: 0.5rem;
                }

                .methods-table-column {
                    text-align: left;
                    font-family: "Poppins";
                    font-size: 20px;
                    line-height: 30px;

                    &:not(:nth-child(-n+2)) {
                        text-align: right;
                    }

                    @media(max-width:$screen_md_max) {
                        &:not(:nth-child(-n+2)) {
                            text-align: left;
                        }

                        &:not(:nth-child(1)) {
                            grid-column: 2 / 2;
                        }

                        &:last-child {
                            grid-column: 3 / 3;
                            grid-row: 1 / 1;
                            text-align: right;
                        }
                    }

                    @media(max-width:$screen_sm_max) {
                        &:not(:nth-child(1)) {
                            grid-column: 2 / 2;
                        }

                        &:last-child {
                            grid-row: auto;
                            text-align: left;
                        }
                    }

                    .mobile-label {
                        font-weight: bold;
                        display: none;

                        @media(max-width:$screen_md_max) {
                            display: inline;
                        }
                    }

                    a {
                        .mobile-label {
                            margin-left: 0.25rem;
                            color: $light-blue;
                        }
                    }
                }
            }
        }
    }
}
