﻿.modal {
    .modal-dialog {
        max-width: 800px;
        margin: 0 auto;
        /*height: 100vh;
        display: flex;
        align-items: center;*/

        &.service-unavailable {
            max-width: 500px;
        }

        @media (max-width: 991px) {
            width: 90%;
            margin: 0 auto;
            /*height: 100vh;
            display: flex;
            align-items: center;*/
        }
    }

    .modal-title {
        line-height: 1.2;
    }

    .modal-content {
        border-radius: 0;

        .modal-header {
            padding-top:2rem;
            padding-left: 2rem;
            padding-right: 2rem;
        }

        .modal-body {
            padding: 2rem;

            .btn {
                margin: 25px 0 0;
            }

            ul {
                font-size: 20px;
                line-height: 30px;
            }
        }

        .modal-footer {
            padding-left: 2rem;
            padding-right: 2rem;

            > :last-child {
                margin-right: 0;
            }
        }
    }

    .close:not(.btn) {
        opacity: 1;
        position: absolute;
        top: 10px;
        right: 10px;
        padding: 0;
        border: none;
        background: none;

        &:before {
            content: '\e908';
            font-size: 18px;
            color: $light-blue;
            font-family: 'de' !important;
        }

        &:hover {
            &:before {
                color: $primary-blue;
            }
        }

        span {
            display: none;
        }
    }

    .form-check {
        input[type='radio'] {
            height: 21px;
            width: 21px;
            border: 1px solid $light-blue;
            border-radius: 50%;
            color: $light-blue;
            -webkit-appearance: none;
            padding: 2px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: $white;

            &:checked {
                &:after {
                    content: '';
                    position: relative;
                    height: 12px;
                    width: 12px;
                    background: $light-blue;
                    border-radius: 50%;
                }
            }
        }

        label {
            margin-left: 10px;
            line-height: 2;
        }
    }


    .input-wrapper {
        display: flex;
        align-items: center;
        border: 1px solid $border-gray;

        input {
            border: none;
            padding: 5px 15px;
            -webkit-appeaerance: none;
            width: 100%;

            &::placeholder {
                opacity: 1;
                color: $dark-gray;
            }
        }

        .btn {
            @include bodytext-sm;
            padding: 0 25px;
            flex-grow: 1;
            width: 220px;

            @media (max-width: 991px) {
                width: 200px;
            }
        }
    }

    .pg-input-wrapper {
        .pg-autocomplete-list {
            left: auto;
            right: auto;
            max-height: 350px !important;
        }
    }

    .text-danger > ul > li {
        --bs-text-opacity: 1;
        color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
    }

    input {
        border-radius: 0;
        border-color: $border-gray;
        border-width: 1.5px;
        line-height: 1.5;
        @include bodytext;

        &[type=number] {
            -webkit-appearance: textarea;
            -moz-appearance: textfield;

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
            }
        }
    }

    .dropdown-wrapper {
        border-radius: 0;
        border: 1.5px solid $border-gray;
        position: relative;
        display: inline-block;
        width: 100%;

        &:before {
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 12px 8px 0 8px;
            border-color: #555954 transparent transparent transparent;
            position: absolute;
            right: 12px;
            top: 20px;
            pointer-events: none;
        }

        select {
            -webkit-appearance: none;
            border: 0;
            background: $white;
            padding: .375rem .75rem;
            display: inline-block;
            width: 100%;
            @include bodytext;

            &:disabled {
                background: #e9ecef;
            }
        }
    }

    label {
        color: $black;
        line-height: 1.5;
        @include bodytext;

        @media (max-width: 991px) {
            line-height: 1.8;
        }
    }

    .error-message > ul > li {
        color: $error-red;
        @include bodytext-sm;
        margin: 5px 0 20px;
        /*display: none;*/
    }

    .error {
        input {
            border-color: $error-red;
        }

        .error-message {
            display: block;
        }
    }
}

.payment-and-billing-modal {
    .modal-body {
        font-size:20px;

        .icheck-iframe-wrapper {
            iframe {
                width: 100%;

                &.cc {
                    height: 970px;
                }

                &.ach {
                    height: 900px;
                }
            }
        }

        .icheck-iframe-loader {
            display: flex;
            align-items: center;
            justify-content: center;

            &.cc {
                height: 970px;
            }

            &.ach {
                height: 900px;
            }

            .spinner {
                height: 100px;
                width: 100px;
                border-width: 8px;
                color: $primary-blue;
            }
        }

        ul.payment-methods-list {
            list-style-type: none;
            padding-left: 0;

            li {
                margin-bottom: 0.5rem;

                &:last-child {
                    margin-bottom: 0;
                }

                input[type=radio] {
                    height: 20px;
                    width: 20px;
                    border: 1px solid #525552;
                    border-radius: 50%;
                    color: #525552;
                    -webkit-appearance: none;
                    padding: 2px;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    vertical-align: middle;
                    background: #525552;
                    cursor: pointer;

                    &:checked {
                        &:after {
                            content: '';
                            position: relative;
                            height: 12px;
                            width: 12px;
                            background: transparent linear-gradient(180deg, #FAFAFA 0%, var(--unnamed-color-888888) 100%) 0% 0% no-repeat padding-box;
                            background: transparent linear-gradient(180deg, #FAFAFA 0%, #888888 100%) 0% 0% no-repeat padding-box;
                            box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
                            border-radius: 50%;
                        }
                    }
                }

                label {
                    font-size: 20px;
                    line-height: 20px;
                    vertical-align: middle;
                    cursor: pointer;
                }
            }
        }

        .form-control {
            font-size: 18px;
        }
    }
}