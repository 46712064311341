@import "Variables.scss";
@import "_typography.scss";
@import "_header.scss";
@import "_block.scss";
@import "_checkout.scss";
@import "_date_picker.scss";
@import "_footer.scss";
@import "_modals.scss";
@import "_components.scss";
@import "_account.scss";
@import "_testing.scss";
@import "_utilities.scss";
@import "_account_login.scss";

body {
    display:flex!important;
    flex-direction:column;
    min-height:100vh;
    font-family: 'Raleway', sans-serif;
    color: $black;

    * {
        outline: none; 
    }
}
.main-content {
    overflow: hidden;
    .home-content {
        &>div:not([class]) {
            &>.row {
                align-items: stretch !important;
            }
        }
    }
} 
@media (max-width:767px){
    #content {
        overflow: hidden;
    }
}
.container {
    max-width:1440px;
    @media(max-width:1449px){
        padding:0 30px;
    }
    .container {
        padding:0;
    }
}

[v-cloak] {
    display: none;
}
   