﻿footer {
    background: $primary-blue;
    padding: 40px 0;
    margin-top:auto;

    &.checkout-footer {
        background: $lighter-gray;
    }

    color: $white;

    p {
        color: $white;
    }

    h2, h3, h4 {
        margin: 0;
        color: $white;
    }

    .content {
        float: left;

        a {
            color: $white;
            text-decoration: none;

            &:hover {
                color: $light-blue;
            }
        }
    }

    .footer-three-blocks {
        .col-xl-4:nth-child(2) {
            padding: 0;
        }
    }

    .seperator {
        border-top: 1px solid $white;
    }

    .checkout-footer-row {
        justify-content: center;
        align-items: center;

        @media (max-width: 767px) {
            justify-content: flex-start;
            align-items: center;
        }
    }

    .checkout-footer-top {
        text-align: center;
        padding: 35px 0;

        @media (max-width: 767px) {
            text-align: left;
            padding: 15px;
        }

        img {
            display: inline;
        }

        p {
            color: $primary-blue;
            @include bodytext-sm;
            line-height: 1.5;
            margin-bottom: 0;
            margin-top: 10px;

            @media (max-width: 767px) {
                br {
                    display: none;
                }

                font-size: 18px;
            }
        }
    }

    .social-links {
        margin: 0;
        padding: 0;
        @media (max-width:767px){
            margin:0 0 10px;
        }
        li {
            list-style-type: none;
            display: inline-block;
            margin-right: 10px;

            a {
                font-size: 16px;
                line-height: 42px;

                span {
                    color: $white;
                }

                &:hover {
                    text-decoration: none;

                    span {
                        color: $light-blue;
                        text-decoration: none;
                    }
                }
            }
        }
    }

    .footer-links {
        margin: 0;
        padding: 0;
        columns: 2;

        li {
            list-style-type: none;

            a {
                @include bodytext;
                color: $white;
                text-decoration: none;

                &:hover {
                    color: $light-blue;
                }
            }
        }
    }

    .footer-logo {
        max-height: 50px;

        @media(min-width:991px) {
            max-height: 72px;
        }
    }

    .footer-blue-row {
        background: $primary-blue;
        color: $white;

        .copyright-info {
            text-align: left;
            color: $white;

            p {
                display: inline-block;

                @media(max-width:767px) {
                    font-size: 14px;
                    line-height: 1.5;
                    width: 100%;
                }
            }
        }

        .bottom-footer-links {
            text-align: left;
            display: inline-block;

            ul {
                margin: 0;
                padding: 0;
                text-align: right;

                li {
                    list-style-type: none;
                    padding: 0 10px;
                    border-left: 1px solid $dark-gray;
                    display: inline-block;
                    margin: 0 !important;
                    line-height: 20px;

                    &:before {
                        display: none !important;
                    }

                    &:first-child {
                        border: none;
                    }

                    a {
                        color: $white !important;
                        text-decoration: none;
                        line-height: 20px;

                        @media(max-width:767px) {
                            font-size: 14px;
                            line-height: 1.5;
                        }

                        @media(max-width:767px) {
                            font-size: 14px;
                            line-height: 1.5;
                        }

                        &:hover {
                            color: $light-blue !important;
                        }
                    }
                }
            }
        }
    }

    .modal {
        p {
            color: $black;
        }

        .modal-title {
            color: $primary-blue;
        }
    }
}
@media (max-width: 991px) {
    footer {
        .icon-text-block {
            .icon {
                padding-top: 5px;
            }

            .content {
                @include bodytext;
            }
        }

        .footer-three-blocks {
            .col-xl-4:nth-child(2) {
                padding: 0 15px;
            }
        }

        .seperator {
            border-top: 1px solid $dark-gray;
        }

        .social-links {
            li {
                a {
                    span {
                        color: $white;
                    }
                }
            }
        }

        .footer-links {
            margin: 25px 0;

            li {
                margin: 0 0 10px;
            }
        }

        .sign-up-wrapper {
            border: 1px solid $primary-blue;
            display: flex;
            max-width: 500px;

            input {
                -webkit-appearance: none;
                outline: none;
                border: none;
                padding: 8px 10px;
                float: left;
                width: 95%;
                text-align:center;
                border-bottom:2px solid $white;
                &::placeholder {
                    color: $white;
                    @include bodytext;
                    opacity: 1;
                }
            }

            button {
                -webkit-appearance: none;
                outline: none;
                border: none;
                display: flex;
                justify-content: center;
                align-items: center;
                background: $light-blue;
                width: 50px;
                float: right;

                span {
                    color: $white;
                    @include bodytext;
                }
            }
        }

        .footer-blue-row .bottom-footer-links {
            text-align: left;

            ul {
                text-align: left;
                margin:0;
                padding:0 !important;
                li {
                    padding: 0 5px;

                    &:nth-child(1) {
                        padding-left: 0;
                    }

                }
            }
        }
    }
}